import { handleEnter } from '../../helpers/forms'

export const PasswordInput = ({ password = '', setPassword = () => {} }) => {
  return (
    <div className='field'>
      <input
        type='password'
        placeholder='Contraseña'
        value={ password }
        onChange={ e => setPassword(e.target.value) }
        onKeyDown={ handleEnter }
        tabIndex='3'
      />
    </div>
  )
}
