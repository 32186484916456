import { handleEnter } from '../../helpers/forms'

export const UsernameInput = ({ username = '', setUsername = () => {} }) => {
  return (
    <div className='field'>
      <input
        type='text'
        placeholder='Nombre de usuario'
        value={ username }
        onChange={ e => setUsername(e.target.value) }
        onKeyDown={ handleEnter }
        tabIndex='2'
      />
    </div>
  )
}
