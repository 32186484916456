import { handleEnter } from '../../helpers/forms'

export const AccountInput = ({ account = '', setAccount = () => {} }) => {
  return (
    <div className='field'>
      <input
        autoFocus
        type='text'
        placeholder='Cuenta de Webfleet'
        value={ account }
        onChange={ e => setAccount(e.target.value) }
        onKeyDown={ handleEnter }
        tabIndex='1'
      />
    </div>
  )
}
