const Loader = ({active}) => {

  const classNames = () => `loading-shadow wait ${active ? 'active' : ''}`

  return (
    <div className={ classNames() }>
      <div className="loading-box">
        <label>Cargando...</label>
        <div className="loading-items">
          <div className="loading-item"></div>
          <div className="loading-item"></div>
          <div className="loading-item"></div>
          <div className="loading-item"></div>
          <div className="loading-item"></div>
          <div className="loading-item"></div>
          <div className="loading-item"></div>
          <div className="loading-item"></div>
          <div className="loading-item"></div>
          <div className="loading-item"></div>
          <div className="loading-item"></div>
          <div className="loading-item"></div>
          <div className="loading-item"></div>
          <div className="loading-item"></div>
          <div className="loading-item"></div>
        </div>
      </div>
    </div>
  )
}

export default Loader
