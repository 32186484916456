import { useContext, useState } from 'react'
import { VehiclesList } from '../components/VehiclesList/VehiclesList'
import { Form } from '../components/elocks/form/Form'
import { destroy, getElock } from '../services/ElocksScreen'
import { AuthContext } from '../auth/authContext'
import ModalQuestion from '../components/ModalQuestion'
import Loader from '../components/Loader'

const ElocksScreen = () => {
  document.title = 'Movo | Elocks'
  const { user } = useContext(AuthContext)
  const [selectedVehicle, setSelectedVehicle] = useState({})
  const [id, setId] = useState('')
  const [name, setName] = useState('')
  const [macAddress, setMacAddress] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [macAddressWithoutChanges, setMacAddressWithoutChanges] = useState('')
  const [showModal, setShowModal] = useState(false)
  const [loading, setLoading] = useState(false)

  const onSelectVehicle = vehicle => {
    setSelectedVehicle(vehicle)
    setErrorMessage('')
    getElock(user.token, vehicle.objectuid)
      .then(response => {
        setId(response.id)
        setName(response.name)
        setMacAddress(response.mac_address)
        setMacAddressWithoutChanges(response.mac_address)
      })
      .catch(clear)
  }

  const onConfirmDelete = () => {
    destroy(user.token, id)
    .then(() => {
      setShowModal(false)
      setSelectedVehicle({})
      clear()
    })
    .catch(() => setShowModal(false))
  }

  const clear = () => {
    setId('')
    setName('')
    setMacAddress('')
    setMacAddressWithoutChanges('')
  }

  return (
    <>
      <Loader active={loading} />
      <ModalQuestion
        title='Eliminar elock'
        question={ `Eliminar la elock puede causar problemas si está en uso. ¿Está seguro que desea eliminar ${name}?` }
        active={ showModal }
        onConfirm={ onConfirmDelete }
        onCancel={ () => setShowModal(false) }
      />
      <div className='elocks-screen'>
        <VehiclesList
          filters
          setLoading={ setLoading }
          selectedVehicle={ selectedVehicle }
          setSelectedVehicle={ onSelectVehicle }
        />
        <div className='elocks-screen-form'>
          <Form
            selectedVehicle={ selectedVehicle }
            token={ user.token }
            id={ id }
            name={ name }
            macAddress={ macAddress }
            setName={ setName }
            setMacAddress={ setMacAddress }
            macAddressWithoutChanges={ macAddressWithoutChanges }
            setSelectedVehicle={ setSelectedVehicle }
            clear={ clear }
            setErrorMessage={ setErrorMessage }
            errorMessage={ errorMessage }
            setShowModal={ setShowModal }
            setLoading={ setLoading }
            selectedAccountId={ user?.selectedAccount?.id }
          />
        </div>
      </div>
    </>
  )
}

export { ElocksScreen }
