import { useState, useContext, useEffect } from 'react'
import { AuthContext } from '../auth/authContext'
import { FullData } from '../components/metricsByVehicle/FullData'
import { Header } from '../components/metricsByVehicle/Header'
import { SearchBar } from '../components/metricsByVehicle/SearchBar'
import Loader from '../components/Loader'
import axios from '../helpers/api'

export const MetricsByVehicleScreen = () => {

  const { user } = useContext(AuthContext)
  const [tableOpen, setTableOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [vehicles, setVehicles] = useState([])
  const [data, setData] = useState([])

  useEffect(() => {
    document.title = 'Movo | Viajes por vehículo'
    setLoading(true)
    const options = {
      headers: { 'Authorization': `Bearer ${user.token}` }
    }
    axios.get(`/vehicles_list/${user.selectedAccount.id}`, options)
      .then(response => setVehicles(response.data))
      .catch(console.error)
      .finally(() => {
        setLoading(false)
      })
  }, [])

  const handleOpenTable = () => setTableOpen(!tableOpen)

  return (
    <>
      <Loader active={ loading } />
      <Header data={ data }/>
      <SearchBar
        handleChkData={ handleOpenTable }
        vehicles={ vehicles }
        setLoading={ setLoading }
        setData={ setData }
        data={ data }
      />
      <FullData open={tableOpen} data={ data.showTripReportExtern } />
    </>
  )
}
