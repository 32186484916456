import CheckListItem from './CheckListItem'

const CheckList = ({
  items=[],
  multiselect=false,
  onSelectItem=()=>{},
  onDeselectItem=()=>{},
  setSelectedItem=[0]
}) => (
  <ul className="c-check-list">
    {
      items.map(i => (
        <CheckListItem
          key={ i.id }
          item={ i }
          multiselect={ multiselect }
          onSelectItem={ onSelectItem }
          onDeselectItem={ onDeselectItem }
          setSelectedItem={ setSelectedItem }
        />
      ))
    }
  </ul>
)

export default CheckList
