const MacAddressInput = ({ macAddress, setMacAddress, setErrorMessage }) => {
  const macAddressHandler = ({ target: { value } }) => {
    setMacAddress(value)
    setErrorMessage('')
  }

  return (
    <div className='field'>
      <input
        type='text'
        value={ macAddress }
        onChange={ macAddressHandler }
        placeholder='Dirección MAC'
      />
    </div>
  )
}

export { MacAddressInput }
