import { useState } from 'react'
import { VehiclesList } from '../VehiclesList/VehiclesList'

const VehicleSearcherToggle = ({
  selectedVehicle = {},
  setSelectedVehicle = () => {}
}) => {
  const [open, setOpen] = useState(false)

  const getClass = () => open ? 'show' : 'hidden'

  const handleClick = vehicle => {
    setOpen(false)
    setSelectedVehicle(vehicle)
  }

  const getSelectedVehicleText = () => {
    if (!selectedVehicle.objectno) return 'Seleccionar vehículo'
    return `${selectedVehicle.objectno} - ${selectedVehicle.objectname}`
  }

  return (
    <div className='c-vehicle-searcher-box'>
      <div
        className='c-vehicle-searcher-text'
        onClick={ () => setOpen(!open) }
      >
        { getSelectedVehicleText() }
      </div>
      <div className={ getClass() }>
        <VehiclesList
          filters
          setSelectedVehicle={ handleClick }
          selectedVehicle={ selectedVehicle }
        />
      </div>
    </div>
  )
}

export { VehicleSearcherToggle }
