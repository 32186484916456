const mapsUrl = 'https://www.google.com/maps/?q='

const coordToDegrees = coord => coord ? coord / 1000000 : 0
const getPosition = (lat, lng) => `${coordToDegrees(lat)},${coordToDegrees(lng)}`
const googleMapsLink = (lat, lng) => `${mapsUrl}${getPosition(lat, lng)}`

export {
  coordToDegrees,
  googleMapsLink
}
