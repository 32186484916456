import axios from '../helpers/api'

const buildHeaders = token => (
  { headers: { 'Authorization': `Bearer ${token}` } }
)

const getVehicles = async (token, accountId) => {
  const url = `/search_connections?account=${accountId}&group=`
  const { data } = await axios.get(url, buildHeaders(token))
  return data
}

const getGroupsData = async (token, accountId) => {
  const url = `/vehicles_by_groups/${accountId}`
  const { data } = await axios.get(url, buildHeaders(token))
  return data
}

export { getVehicles, getGroupsData }
