import { useContext, useEffect, useState } from 'react'
import { AuthContext } from '../../auth/authContext'
import { getGroupsData, getVehicles } from '../../services/VehiclesList'
import { List } from './List'
import { VehiclesNotFound } from './VehiclesNotFound'
import { FilterInputs } from './FilterInputs'

const defaultGroup = () => ({ id: 'Todos', label: 'Todos', value: 'Todos' })
const alphabeticalOrder = (a, b) => a.label.toUpperCase() > b.label.toUpperCase()
const sortedGroups = groups => groups.sort(alphabeticalOrder)
const addDefaultGroup = data => ([ defaultGroup(), ...sortedGroups(data) ])

const VehiclesList = ({
  filters = false,
  selectedVehicle = defaultGroup(),
  setSelectedVehicle = () => {},
  setLoading = () => {}
}) => {
  const { user } = useContext(AuthContext)

  const [vehicles,                 setVehicles] = useState([])
  const [groups,                     setGroups] = useState([])
  const [groupRelations,     setGroupRelations] = useState([])
  const [selectedGroup,       setSelectedGroup] = useState(defaultGroup())
  const [txtSearch,               setTxtSearch] = useState('')
  const [filteredVehicles, setFilteredVehicles] = useState([])
  const [groupOpen,               setGroupOpen] = useState(false)

  const setData = (vehicles, { groups, relations }) => {
    setFilteredVehicles(vehicles)
    setVehicles(vehicles)
    setGroups(addDefaultGroup(groups))
    setGroupRelations(relations)
  }

  const loadData = async () => {
    try {
      setLoading(true)
      const token          = user.token
      const accountId      = user.selectedAccount.id
      const vehiclesResult = await getVehicles(token, accountId)
      const groupsResult   = await getGroupsData(token, accountId)
      setData(vehiclesResult, groupsResult)
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    loadData()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const result = filterData(txtSearch)
    setFilteredVehicles(result)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedGroup])

  const handleSelectGroup = g => {
    setSelectedGroup(g)
    setGroupOpen(false)
  }

  const getUidsBySelectedGroup = () => groupRelations
    .filter(g => g.objectgroupuid === selectedGroup.id)
    .map(g => g.objectuid)

  const filterByGroup = () => {
    if (selectedGroup.id === 'Todos') return vehicles
    const vehiclesUids = getUidsBySelectedGroup()
    return vehicles.filter(r => vehiclesUids.includes(r.objectuid))
  }

  const byObjectnoAndObjectname = (vehicle, inputText) => (
    `${vehicle.objectno} - ${vehicle.objectname}`
      .toLowerCase()
      .includes(inputText)
  )

  const filterData = text => {
    const searchText = text.trim().toLowerCase()
    const groupfiltered = filterByGroup()
    if (!searchText.length) return groupfiltered
    return groupfiltered.filter(v => byObjectnoAndObjectname(v, searchText))
  }

  const handleChangeTxtSearch = ({ target: { value } }) => {
    setTxtSearch(value)
    setFilteredVehicles(filterData(value))
  }

  return (
    <div className='c-vehicles-list'>
      {
        filters ?
          <header>
            <FilterInputs
              setGroupOpen={ setGroupOpen }
              groupName={ selectedGroup.label }
              groupOpen={ groupOpen }
              groups={ groups }
              handleSelectGroup={ handleSelectGroup }
              txtSearch={ txtSearch }
              handleChangeTxtSearch={ handleChangeTxtSearch }
            />
          </header>
        : <></>
      }
      <div className='c-vehicles-list-body'>
        {
          filteredVehicles.length ?
            <List
              objectuidSelected={ selectedVehicle.objectuid }
              onSelectVehicle={ setSelectedVehicle }
              filteredVehicles={ filteredVehicles }
            />
          :
            <VehiclesNotFound />
        }
      </div>
    </div>
  )
}

export { VehiclesList }
