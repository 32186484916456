export const Switch = ({ value=false, name='', title='', onChange=()=>{} }) => {

  return (
    <div className="c-switch">
      <input
        key={value}
        id={name}
        type="checkbox"
        defaultChecked={value}
        onChange={ onChange }
      />
      <label htmlFor={name}></label>
      <label htmlFor={name}>{title}</label>
    </div>
  )
}
