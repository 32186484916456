import { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { AuthContext } from '../auth/authContext'
import { types } from '../types/types'
import axios from '../helpers/api'
import Alert from '../components/Alert'

export const LoginScreen = () => {

  const navigate = useNavigate()
  const { dispatch } = useContext(AuthContext)
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [errorMessage, setErrorMessage] = useState(null)

  const handleLogin = async() => {
    setErrorMessage(null)
    try {
      const params = { email: email, password: password }
      const result = await axios.post('/login', params)
      const options = {
        headers: { 'Authorization': `Bearer ${result.data.token}` }
      }
      const { data } = await axios.get('/webfleet_accounts_by_token', options)
      let selectedAccount = { id: 0 }
      if (data.length) selectedAccount = data[0]
      const action = {
        type: types.login,
        payload: {
          name: result.data.user.name,
          lastName: result.data.user.last_name,
          motherLastName: result.data.user.mother_last_name,
          fullName: result.data.user.full_name,
          email: result.data.user.email,
          role: result.data.user.role,
          token: result.data.token,
          selectedAccount: selectedAccount
        }
      }
      axios.defaults.headers.common['Authorization'] = `Bearer ${result.data.token}`
      dispatch(action)
      const lastPatch = '/'
      navigate(lastPatch, { replace: true })
    }
    catch (e) {
      if (e.request.status < 500)
        setErrorMessage(e.response.data.error)
      else
        setErrorMessage('Ocurrió un problema con el servidor, vuelva a intentarlo más tarde')
    }
  }

  const handleEnter = e => {
    if (e.key.toLowerCase() !== 'enter') return null
    const form = e.target.form
    const index = [...form].indexOf(e.target)
    form.elements[index + 1].focus()
    e.preventDefault()
  }

  const handleLoginKey = e => {
    if (e.key.toLowerCase() !== 'enter') return null
    handleLogin()
    e.preventDefault()
  }

  return (
    <>
      <div className='bg'></div>
      <form id="frmLogin">
        <img src="/movo-logo-full.png" alt="Movo logo" />
        <div className="separator" />
        <div className='col'>
          <input
            id="txtUsername"
            onKeyDown={handleEnter}
            placeholder='Email'
            type="email"
            tabIndex="1"
            autoFocus
            value={email}
            onChange={e => setEmail(e.target.value)}
          />
          <input
            id="txtPassword"
            onKeyDown={handleLoginKey}
            placeholder='Contraseña'
            type="password"
            value={password}
            onChange={e => setPassword(e.target.value)}
            tabIndex="2"
          />
          <button
            type="button"
            onKeyDown={handleLoginKey}
            onClick={handleLogin}
            tabIndex="3"
          >Login</button>
          <Alert show={errorMessage} value={errorMessage} />
        </div>
      </form>
    </>
  )
}
