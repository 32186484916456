const ModalQuestion = ({
  title='',
  question='',
  yesLabel='Aceptar',
  noLabel='Cancelar',
  active=false,
  onConfirm=()=>{},
  onCancel=()=>{}
}) => {

  const classNames = () => `loading-shadow ${active ? 'active' : ''}`

  const close = e => {
    e.preventDefault()
    if (e.target !== e.currentTarget) return null
    onCancel()
  }

  return (
    <div className={ classNames() } onClick={close} >
      <div className="l-modal-question">
        <header>
          <h2>{title}</h2>
        </header>
        <div className="body">{question}</div>
        <footer>
          <button onClick={close} className="negative">{noLabel}</button>
          <button onClick={onConfirm}>{yesLabel}</button>
        </footer>
      </div>
    </div>
  )
}

export default ModalQuestion
