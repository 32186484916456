export const HeaderCardItem = ({ 
  title, 
  quantity, 
  measureUnit = '', 
  danger = false
}) => {

  const getClassName = () => `item ${(danger ? 'danger' : '')}`

  return (
    <div className={ getClassName() }>
      <h2>{ title }</h2>
      <h1>{ quantity }</h1>
      <h3>{ measureUnit }</h3>
    </div>
  )
}
