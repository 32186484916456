const CheckListItem = ({
  item,
  multiselect=false,
  onSelectItem=()=>{},
  onDeselectItem=()=>{},
  setSelectedItem=[0]
}) => {

  const isSelectedItem = () => {
    if (multiselect)
      return setSelectedItem.map(i => i.id).includes(item.id)
    return item.id === setSelectedItem.id
  }

  const getClass = () => `c-check-list-item ${isSelectedItem() ? 'selected' : ''}`

  const handleOnClick = () => {
    if (isSelectedItem())
      onDeselectItem(item)
    else
      onSelectItem(item)
  }

  return (
    <li
      className={ getClass() }
      onClick={ handleOnClick }
    >
      <figure></figure>
      <div>
        <div>
          <label>Cuenta:</label>
          <label>{ item.account }</label>
        </div>
        <div>
          <label>Usuario:</label>
          <label>{ item.user }</label>
        </div>
      </div>
    </li>
  )
}

export default CheckListItem
