const DeleteButton = ({
  setShowModal = () => {},
  elockId = '',
  hasSelectedVehicle = false
}) => {
  const hasVehicleElock = () => hasSelectedVehicle && elockId

  const onDelete = () => setShowModal(true)

  return (
    <>
      {
        hasVehicleElock() ?
          <button
            type='button'
            className='delete-btn'
            onClick={ onDelete }
          >
            Eliminar eLock
          </button>
        : <></>
      }
    </>
  )
}

export { DeleteButton }
