import { useState, useContext, useEffect } from 'react'
import { AuthContext } from '../auth/authContext'
import { SearchBar } from '../components/connections/SearchBar'
import { FullData } from '../components/connections/FullData'
import Loader from '../components/Loader'
import axios from '../helpers/api'

export const ConnectionsScreen = () => {

  const { user } = useContext(AuthContext)
  const [loading, setLoading] = useState(false)
  const [groups, setGroups] = useState([])
  const [groupRelations, setGroupRelations] = useState([])
  const [selectedGroup, setSelectedGroup] = useState({ id: 'Todos', label: 'Todos', value: 'Todos' })
  const [data, setData] = useState([])
  const [filteredVehicles, setFilteredVehicles] = useState([])
  const [optimalPositionTime, setOptimalPositionTime] = useState(3)
  const [warningPositionTime, setWarningPositionTime] = useState(15)

  useEffect(() => {
    document.title = 'Movo | Última conexión'
    setLoading(true)
    const options = {
      headers: { 'Authorization': `Bearer ${user.token}` }
    }
    axios.get(`/vehicles_by_groups/${user.selectedAccount.id}`, options)
      .then(response => {
        setGroups([
          { id: "Todos", label: "Todos", value: "Todos" },
          ...response.data.groups
        ])
        setGroupRelations(response.data.relations)
      })
      .catch(console.error)
      .finally(() => setLoading(false))
  }, [])

  return (
    <>
      <Loader active={ loading } />
      <SearchBar
        groups={ groups }
        groupRelations={ groupRelations }
        selectedGroup={ selectedGroup }
        setSelectedGroup={ setSelectedGroup }
        setData={ setData }
        data={ data }
        filteredVehicles={ filteredVehicles }
        setFilteredVehicles={ setFilteredVehicles }
        okTime={ optimalPositionTime }
        setOkTime={ setOptimalPositionTime }
        warningTime={ warningPositionTime }
        setWarningTime={ setWarningPositionTime }
      />
      <FullData
        data={ filteredVehicles }
        selectedGroup={ selectedGroup }
        groupRelations={ groupRelations }
        okTimeValue={ optimalPositionTime }
        warningTimeValue={ warningPositionTime }
      />
    </>
  )
}
