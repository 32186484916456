import { useContext, useEffect, useState } from "react"
import { AuthContext } from "../auth/authContext"
import axios from '../helpers/api'
import Loader from '../components/Loader'
import { Link } from 'react-router-dom'
import ModalQuestion from '../components/ModalQuestion'

const defaultAccount = { account: '', user: '' }

export const WebfleetAccountsScreen = () =>  {

  const { user } = useContext(AuthContext)
  const [usersList, setUsersList] = useState([])
  const [loading, setLoading] = useState(true)
  const [showModal, setShowModal] = useState(false)
  const [selectedAccount, setSelectedAccount] = useState(defaultAccount)

  const load = () => {
    document.title = 'Movo | Cuentas de Webfleet'
    const options = {
      headers: { 'Authorization': `Bearer ${user.token}` }
    }
    axios.get('/webfleet_accounts', options)
      .then(request => setUsersList(request.data))
      .catch(console.error)
      .finally(() => setLoading(false))
  }

  useEffect(() => { load() }, [])

  const sortList = () => usersList.sort(
    (a, b) => `${a.account} ${a.user}`.toLowerCase() > `${b.account} ${b.user}`.toLowerCase()
  )

  const handleDeleteAccount = () => {
    const options = {
      headers: { 'Authorization': `Bearer ${user.token}` }
    }
    axios.delete(`/webfleet_accounts/${selectedAccount.id}`, options)
      .then(response => {})
      .catch(console.error)
      .finally(() => {
        setShowModal(false)
        load()
      })
  }

  return (
    <>
      <Loader active={ loading } />
      <ModalQuestion
        title="Eliminar Cuenta de Webfleet"
        question={`¿Está seguro que desea eliminar la cuenta: [${selectedAccount.account}] [${selectedAccount.user}]?`}
        active={showModal}
        onConfirm={handleDeleteAccount}
        onCancel={() => setShowModal(false)} />
      <div className="l-admin header animate__animated animate__fadeIn">
        <div className="card">
          <header>
            <h1>Cuentas de Webfleet</h1>
            <Link className="btn" to="/admin/webfleet_accounts/new">Nueva cuenta</Link>
          </header>
          <div className="body">
            <div className="table">
              <header>
                <div>Cuenta</div>
                <div>Usuario</div>
                <div className="action"></div>
                <div className="action"></div>
              </header>
              <div className="table-body">
                {
                  sortList().map(a => (
                    <div className="table-row" key={a.id}>
                      <div>{a.account}</div>
                      <div>{a.user}</div>
                      <div className="action">
                        <a className="btn" href={`/admin/webfleet_accounts/edit/${a.id}`}>Editar</a>
                      </div>
                      <div className="action">
                        <button
                          type="button"
                          className="danger"
                          onClick={() => {
                            setSelectedAccount(a)
                            setShowModal(true)
                          }}
                        >
                          Eliminar
                        </button>
                      </div>
                    </div>
                  ))
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
