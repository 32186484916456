const CompassIcon = ({ course=0 }) => {

  const getOrientation = () => ({ transform: `rotate(${course}deg)` })

  return (
    <svg
      enableBackground="new 0 0 48 48"
      height="14"
      style={ getOrientation() }
      version="1.1"
      viewBox="0 0 48 48"
      width="14"
      x="0px"
      y="0px"
    >
      <path d="M37.2 37.6L25.1 9c-.2-.5-.5-1-1.1-1-.6 0-.9.5-1.1 1L10.8 37.6c-.4.9 0 1.9.9 2.3.5.2 1 .2 1.4 0L24 34.7l10.9 5.1c.4.2.9.2 1.4 0 .9-.3 1.3-1.3.9-2.2z"></path>
    </svg>
  )
}

export default CompassIcon
