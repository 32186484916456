import React from 'react'
import { NavLink } from 'react-router-dom'

export const NavbarItem = ({ name, icon, path }) => {

  const isSelected = () => (
    path.split('/')[1] === window.location.pathname.split('/')[1]
  )

  const getClasses = () => isSelected() ? 'menu-item selected' : 'menu-item'

  return (
    <li>
      <NavLink className={ getClasses() } to={ path }>
        <i className="material-icons">{ icon }</i>
        <label>{ name }</label>
      </NavLink>
    </li>
  )
}
