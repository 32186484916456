import { useState, useContext, useEffect } from 'react'
import { AuthContext } from '../../auth/authContext'
import VehiclesCheckList from '../VehiclesCheckList'
import { DropDown } from '../DropDown'
import { CSVLink } from 'react-csv'
import axios from '../../helpers/api'

const numbersOnly = /^[0-9\b]+$/

export const SearchBar = ({
  groups=[],
  groupRelations=[],
  selectedGroup={ id: 'Todos', label: 'Todos', value: 'Todos' },
  setSelectedGroup=()=>{},
  setLoading=()=>{},
  setData=()=>{},
  data=[],
  filteredVehicles=[],
  setFilteredVehicles=()=>{},
  okTime,
  setOkTime=()=>{},
  warningTime,
  setWarningTime=()=>{}
}) => {

  const { user } = useContext(AuthContext)
  const [openDownload, setOpendownload] = useState(false)
  const [openVehicles, setOpenVehicles] = useState(false)

  const handleOptimalPositionTime = e => {
    if (!numbersOnly.test(e.target.value)) return null
    setOkTime(e.target.value)
    if (e.target.value >= warningTime)
      setWarningTime(parseInt(e.target.value) + 1)
  }
  const handleWarningPositionTime = e => {
    if (!numbersOnly.test(e.target.value)) return null
    setWarningTime(e.target.value)
  }

  const handleSearch = () => {
    setLoading(true)
    const options = {
      headers: { 'Authorization': `Bearer ${user.token}` }
    }
    const url = `/search_connections?account=${user.selectedAccount.id}&group=`
    axios.get(url, options)
      .then(response => {
        setFilteredVehicles(response.data || [])
        setData(response.data)
      })
      .catch(console.error)
      .finally(() => setLoading(false))
  }

  useEffect(handleSearch, [])

  const handleDownloadList = () => setOpendownload(!openDownload)

  const handleSelectVehicle = vehicle => {
    const cleanedArray = [...new Set([...filteredVehicles, vehicle])]
    setFilteredVehicles(cleanedArray)
  }

  const handleDeselectVehicle = vehicle => {
    const cleanedArray = filteredVehicles.filter(a => a.objectuid !== vehicle.objectuid)
    setFilteredVehicles(cleanedArray)
  }

  const filteredData = () => {
    if (selectedGroup.id === 'Todos') return data
    const vehiclesId = groupRelations
      .filter(g => g.objectgroupuid === selectedGroup.id)
      .map(g => g.objectuid)
    return data.filter(r => vehiclesId.includes(r.objectuid))
  }

  return (
    <div className="search-bar l-connections">
      <div className="field dropdown">
        <label className="title">Grupos</label>
        <DropDown
          id="drpVehicle"
          value={ selectedGroup.id }
          expandUp={ true }
          list={ groups }
          onSelect={ setSelectedGroup }
        />
      </div>
      <div className="field">
        <label className="title"></label>
        <button id="btnBuscar" type="button" onClick={ () => setOpenVehicles(!openVehicles) }>
          Vehículos
          <i className="material-icons">directions_car_filled</i>
        </button>
        <div className={ `selector-box ${openVehicles ? 'open' : ''}` }>
          <VehiclesCheckList
            vehicles={ filteredData() }
            onSelect={ handleSelectVehicle }
            onDeselect={ handleDeselectVehicle }
            setSelectedVehicle={ filteredVehicles }
          />
        </div>
      </div>
      <div className="field small">
        <div>
          <label className="title">Posicionamiento óptimo</label>
          <div>
            <input type="number" value={ okTime } onChange={ handleOptimalPositionTime } />
            <span>minutos</span>
          </div>
        </div>
        <div>
          <label className="title">Posicionamiento precaución</label>
          <div>
            <input type="number" value={ warningTime } onChange={ handleWarningPositionTime } />
            <span>minutos</span>
          </div>
        </div>
        <div>
          <label className="title">Sin posicionamiento</label>
          <div>
            <input type="number" disabled value={ parseInt(warningTime) + 1 } />
            <span>minutos</span>
          </div>
        </div>
      </div>
      <div className="field">
        <label className="title"></label>
        <div className="input-field" id="btnDownload" onClick={ handleDownloadList }>
          <label>Descargar</label>
          <i className="material-icons">arrow_drop_down</i>
        </div>
        <ul id="btnDownloadList" className={ `searchbar-dropdown ${openDownload ? 'selected' : ''}` }>
          <li>PDF</li>
          <li>
            <CSVLink
              data={ data || [] }
              filename={ 'bitacora_posiciones.csv' }
              target="_blank"
            >
              CSV
            </CSVLink>
          </li>
        </ul>
      </div>
    </div>
  )
}
