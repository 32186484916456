import { useState, useContext, useEffect } from 'react'
import { AuthContext } from '../auth/authContext'
import { FullData } from '../components/positionLog/FullData'
import { SearchBar } from '../components/positionLog/SearchBar'
import Loader from '../components/Loader'
import axios from '../helpers/api'

export const PositionLogScreen = () => {

  const { user } = useContext(AuthContext)
  const [loading, setLoading] = useState(false)
  const [vehicles, setVehicles] = useState([])
  const [data, setData] = useState([])

  useEffect(() => {
    document.title = 'Movo | Bitácora de posiciones'
    setLoading(true)
    const options = {
      headers: { 'Authorization': `Bearer ${user.token}` }
    }
    axios.get(`/vehicles_list/${user.selectedAccount.id}`, options)
      .then(response => setVehicles(response.data))
      .catch(console.error)
      .finally(() => setLoading(false))
  }, [])

  return (
    <>
      <Loader active={ loading } />
      <SearchBar
        setLoading={ setLoading }
        setData={ setData }
        data={ data }
      />
      <FullData data={ data } />
    </>
  )
}
