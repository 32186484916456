import { useState, useContext } from 'react'
import { AuthContext } from '../../auth/authContext'
import { DateTimePicker, toDateFormat } from '../../components/DateTimePicker'
import { CSVLink } from 'react-csv'
import axios from '../../helpers/api'
import {
  VehicleSearcherToggle
} from '../vehicleSearcherToggle/VehicleSearcherToggle'

export const SearchBar = ({
  setLoading=()=>{},
  setData=()=>{},
  data=[]
}) => {

  const { user } = useContext(AuthContext)
  const [beginDate, setBeginDate] = useState(new Date())
  const [endDate, setEndDate]     = useState(new Date())
  const [selectedVehicle, setSelectedVehicle] = useState({})
  const [openDownload, setOpendownload] = useState(false)

  const handleSearch = () => {
    setLoading(true)
    const options = {
      headers: { 'Authorization': `Bearer ${user.token}` }
    }
    const url = `/search_position_log?account=${user.selectedAccount.id}&vehicle=${selectedVehicle.objectuid}&from=${toDateFormat(beginDate)}&to=${toDateFormat(endDate)}`
    axios.get(url, options)
      .then(response => {
        setData(response.data)
      })
      .catch(console.error)
      .finally(() => {
        setLoading(false)
      })
  }

  const handleDownloadList = () => setOpendownload(!openDownload)

  return (
    <div className="search-bar l-position-log">
      <div className="field">
        <label className="title">Vehículo</label>
        <VehicleSearcherToggle
          selectedVehicle={ selectedVehicle }
          setSelectedVehicle={ setSelectedVehicle }
        />
      </div>
      <div className="field">
        <label className="title">Desde</label>
        <DateTimePicker
          id='dtpFrom'
          value={ beginDate }
          onChange={ d => setBeginDate(d) }
        />
      </div>
      <div className="field">
        <label className="title">Hasta</label>
        <DateTimePicker
          id='dtpTo'
          value={ endDate }
          onChange={ d => setEndDate(d) }
        />
      </div>
      <div className="field">
        <label className="title"></label>
        <button id="btnBuscar" type="button" onClick={ handleSearch }>
          Buscar
          <i className="material-icons">search</i>
        </button>
      </div>
      <div className="field">
        <label className="title"></label>
        <div className="input-field" id="btnDownload" onClick={ handleDownloadList }>
          <label>Descargar</label>
          <i className="material-icons">arrow_drop_down</i>
        </div>
        <ul id="btnDownloadList" className={ `searchbar-dropdown ${openDownload ? 'selected' : ''}` }>
          <li>PDF</li>
          <li>
            <CSVLink
              data={ data || [] }
              filename={ 'bitacora_posiciones.csv' }
              target="_blank"
            >
              CSV
            </CSVLink>
          </li>
        </ul>
      </div>
    </div>
  )
}
