const webfleetToDate = text => {
  const year = text.substring(6, 10)
  const month = -1 + parseInt(text.substring(3, 5))
  const day = text.substring(0, 2)
  const hour = text.substring(11, 13)
  const minutes = text.substring(14, 16)
  return new Date(year, month, day, hour, minutes, 0)
}

const timeInMinutesSince = date => {
  if (!date) return 1000000
  const now = new Date()
  const difference = now.getTime() - date.getTime()
  return Math.round(difference / 60000);
}

export {
  webfleetToDate,
  timeInMinutesSince
}
