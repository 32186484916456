import { Header } from './Header'
import { NameInput } from './NameInput'
import { MacAddressInput } from './MacAddressInput'
import { DeleteButton } from './DeleteButton'
import { SaveButton } from './SaveButton'
import Alert from '../../Alert'

const Form = ({
  selectedVehicle = {},
  token              = '',
  id                 = '',
  name               = '',
  macAddress         = '',
  errorMessage       = '',
  selectedAccountId  = '',
  macAddressWithoutChanges = '',
  setShowModal       = () => {},
  setLoading         = () => {},
  setName            = () => {},
  setMacAddress      = () => {},
  clear              = () => {},
  setErrorMessage    = () => {},
  setSelectedVehicle = () => {},
}) => {
  const buildParams = () => ({
    name:        name,
    mac_address: macAddress,
    objectuid:   selectedVehicle.objectuid,
    objectno:    selectedVehicle.objectno,
    webfleet_account_id: selectedAccountId
  })

  const clearForm = () => {
    clear()
    setSelectedVehicle({})
  }

  return (
    <form className='form'>
      <Header selectedVehicle={ selectedVehicle } />
      <div className='fields'>
        <NameInput
          name={ name }
          setName={ setName }
          setErrorMessage={ setErrorMessage }
        />
        <MacAddressInput
          macAddress={ macAddress }
          setMacAddress={ setMacAddress }
          setErrorMessage={ setErrorMessage }
        />
      </div>
      <footer className='actions'>
        <SaveButton
          id={ id }
          token={ token }
          clear={ clearForm }
          params={ buildParams() }
          setLoading={ setLoading }
          macAddress={ macAddress }
          setErrorMessage={ setErrorMessage }
          hasSelectedVehicle={ !!selectedVehicle.objectuid }
          macAddressWithoutChanges={ macAddressWithoutChanges }
        />
        <DeleteButton
          elockId={ id }
          setLoading={ setLoading }
          setShowModal={ setShowModal }
          hasSelectedVehicle={ !!selectedVehicle.objectuid }
        />
      </footer>
      <Alert show={ errorMessage } value={ errorMessage } />
    </form>
  )
}

export { Form }
