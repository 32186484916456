import { Item } from './Item.js'

const List = ({
  objectuidSelected = '',
  onSelectVehicle = () => {},
  filteredVehicles = []
}) => {
  return (
    <ul className='vehicle-compact-list'>
      {
        filteredVehicles.map(v => (
          <Item
            vehicle={ v }
            objectuidSelected={ objectuidSelected }
            onSelectVehicle={ onSelectVehicle }
          />
        ))
      }
    </ul>
  )
}

export { List }
