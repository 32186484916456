import { useContext, useState, useEffect } from 'react'
import { AuthContext } from '../auth/authContext'
import axios from '../helpers/api'
import Alert from '../components/Alert'
import { NavLink } from 'react-router-dom'

export const ChangePasswordScreen = () => {

  const { user } = useContext(AuthContext)
  const [currentPassword, setCurrentPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [repeatPassword, setRepeatPassword] = useState('')
  const [formValidationMessage, setFormValidationMessage] = useState('')
  const [ok, setOk] = useState('')

  useEffect(() => {
    document.title = 'Movo | Cambiar contraseña'
  }, [])

  const clean = () => {
    setCurrentPassword('')
    setNewPassword('')
    setRepeatPassword('')
  }

  const handleEnter = e => {
    if (e.key.toLowerCase() !== 'enter') return null
    const form = e.target.form
    const index = [...form].indexOf(e.target)
    form.elements[index + 1].focus()
    e.preventDefault()
  }

  const handleKeyDown = e => {
    if (e.key.toLowerCase() !== 'enter') return null
    changePassword()
    e.preventDefault()
  }

  const isValidForm = () => {
    let result = ''
    if (newPassword !== repeatPassword)
      result = 'Repita correctamente la nueva contraseña'
    else if (currentPassword.length <= 0)
      result = 'Escriba la contraseña actual'
    else if (newPassword.length <= 0)
      result = 'Escriba la nueva contraseña'
    setFormValidationMessage(result)
    return !result
  }

  const changePassword = async () => {
    setOk('')
    if (!isValidForm()) return null
    const params = {
      old_password: currentPassword,
      user: {
        password: newPassword,
        password_confirmation: repeatPassword
      }
    }
    const options = {
      headers: { 'Authorization': `Bearer ${user.token}` }
    }
    axios.post('/change_password', params, options)
      .then(response => {
        clean()
        setOk(response.data)
      })
      .catch(error => setFormValidationMessage(error.response.data))
  }

  return (
    <div className="l-settings header animate__animated animate__fadeIn">
      <NavLink id="changePasswordBack" className="back-button material-icons" to="/settings">arrow_back</NavLink>
      <form className="card" id="cardChangePassword">
        <header>
          <h1>Cambio de contraseña</h1>
        </header>
        <div className="body">
          <div className="field">
            <input
              autoFocus
              type="password"
              placeholder="Contraseña actual"
              value={currentPassword}
              onChange={e => setCurrentPassword(e.target.value)}
              onKeyDown={handleEnter}
            />
          </div>
          <div className="field">
            <input
              type="password"
              placeholder="Contraseña nueva"
              value={newPassword}
              onChange={e => setNewPassword(e.target.value)}
              onKeyDown={handleEnter}
            />
          </div>
          <div className="field">
            <input
              type="password"
              placeholder="Repita su nueva contraseña"
              value={repeatPassword}
              onChange={e => setRepeatPassword(e.target.value)}
              onKeyDown={handleKeyDown}
            />
          </div>
          <Alert show={formValidationMessage} value={formValidationMessage} />
          <Alert type={'info'} show={ok} value={ok} />
        </div>
        <footer>
          <button
            type="button"
            onKeyDown={handleKeyDown}
            onClick={changePassword}
          >
            Cambiar contraseña
          </button>
        </footer>
      </form>
    </div>
  )
}
