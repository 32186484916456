const StandstillIcon = ({ ignition=0 }) => {

  const getColor = () => ({ fill: `${ignition === 0 ? '#748088' : '#1D2193'}` })

  return (
    <svg
      enableBackground="new 0 0 48 48"
      height="14"
      version="1.1"
      viewBox="0 0 48 48"
      width="14"
      x="0px"
      y="0px"
      style={ getColor() }
    >
      <path d="M25.47,15.76H21.09v7.87H25.4a4.07,4.07,0,0,0,2.91-1,3.55,3.55,0,0,0,1-2.7,4.39,4.39,0,0,0-1-3.05A3.64,3.64,0,0,0,25.47,15.76Z"></path>
      <path d="M41.66,2H6.29A4.3,4.3,0,0,0,2,6.31V41.69A4.3,4.3,0,0,0,6.29,46H41.66A4.3,4.3,0,0,0,46,41.69V6.31A4.3,4.3,0,0,0,41.66,2ZM33.12,26.31q-2.86,2.39-7.84,2.39h-4.2V38H14.51V10.68H25.4a12.68,12.68,0,0,1,5.54,1.15,8.67,8.67,0,0,1,3.73,3.28A9,9,0,0,1,36,19.93,7.89,7.89,0,0,1,33.12,26.31Z"></path>
    </svg>
  )
}

export default StandstillIcon
