import { useState } from 'react'
import { minutesToHours, toKmFormat, toLiters, toSpeedFormat } from "../../helpers/string_format_helpers"
import { Switch } from '../../components/Switch'

const googleMapsUrl = 'https://www.google.com/maps/dir/'

export const FullData = ({ open, data=[] }) => {

  const [showCoordinates, setShowCoordinates] = useState(false)
  const handleSwitch = e => {
    setShowCoordinates(e.target.checked)
  }

  const coordToDegrees = coord => coord / 1000000
  const getPosition = (lat, lng) => `${coordToDegrees(lat)},${coordToDegrees(lng)}`
  const googleMapsLink = (startLat, startLng, endLat, endLng) =>
    `${googleMapsUrl}${getPosition(startLat, startLng)}/${getPosition(endLat, endLng)}`

  return (
    <div id="full-data" className={ open ? 'open' : '' }>
      <div className="field">
        <Switch
          name="chkCoords"
          title="Ver coordenadas"
          value={ showCoordinates }
          onChange={ handleSwitch }
        />
      </div>
      <div className="table">
        <div className="head">
          <div className="col date">Fecha</div>
          {
            showCoordinates ?
            (<div className="col coord">Coordenadas</div>) :
            (<div className="col dir">Dirección</div>)
          }
          <div className="col odometer">Odómetro</div>
          <div className="col distance">Distancia</div>
          <div className="col duration">Duración</div>
          <div className="col speed">Velocidad</div>
          <div className="col slow-motion">Ralentí</div>
          <div className="col driver">Chofer</div>
        </div>
       {
         data?.length ?
         data.map(r => (
          <div className="row" key={ r.tripid }>
            <div className="col date">
              <label className="inital">Inicio</label>
              <span>{ r.start_time }</span>
              <label className="final">Fin</label>
              <span>{ r.end_time }</span>
            </div>
            {
              showCoordinates ?
              (
                <div className="col coord">
                  <label className="inital">Inicio</label>
                  <span>
                    { coordToDegrees(r.start_latitude) }, { coordToDegrees(r.start_longitude) }
                    <a
                      href={ googleMapsLink(
                        r.start_latitude,
                        r.start_longitude,
                        r.end_latitude,
                        r.end_longitude
                      ) }
                      target="_blank"
                      rel="noreferrer"
                      title="Ver en google maps"
                    >
                      <i className="material-icons">map</i>
                    </a>
                  </span>

                  <label className="final">Fin</label>
                  <span>
                    { coordToDegrees(r.end_latitude) }, { coordToDegrees(r.end_longitude) }
                    <a
                      href={ googleMapsLink(
                        r.start_latitude,
                        r.start_longitude,
                        r.end_latitude,
                        r.end_longitude
                      ) }
                      target="_blank"
                      rel="noreferrer"
                      title="Ver en google maps"
                    >
                      <i className="material-icons">map</i>
                    </a>
                  </span>
                </div>
              ) :
              (
                <div className="col dir">
                  <label className="inital">Inicio</label>
                  <span>{ r.start_postext }</span>
                  <label className="final">Fin</label>
                  <span>{ r.end_postext }</span>
                </div>
              )
            }
            <div className="col odometer">
              <label className="inital">Inicio</label>
              <span>{ toKmFormat(r.start_odometer) }</span>
              <label className="final">Fin</label>
              <span>{ toKmFormat(r.end_odometer) }</span>
            </div>
            <div className="col distance">
              <span>{ toKmFormat(r.distance) }</span>
            </div>
            <div className="col duration">
              <span>{ minutesToHours(r.duration) }</span>
            </div>
            <div className="col speed">
              <label className="inital">Promedio</label>
              <span>{ toSpeedFormat(r.avg_speed) }</span>
              <label className="final">Máxima</label>
              <span>{ toSpeedFormat(r.max_speed) }</span>
            </div>
            <div className="col slow-motion">
              <label className="inital">Tiempo</label>
              <span>{ minutesToHours(r.idle_time) }</span>
              <label className="final">Combustible</label>
              <span>{ toLiters(r.idling_indicator) }</span>
            </div>
            <div className="col driver">
              <label className="inital">Id</label>
              <span>{ r.driverno }</span>
              <label className="final">Nombre</label>
              <span>{ r.drivername }</span>
            </div>
          </div>
        )) :
        <></>
      }
      </div>
    </div>
  )
}
