import { useContext, useState, useEffect } from 'react'
import { AuthContext } from '../auth/authContext'
import { types } from '../types/types'
import axios from '../helpers/api'
import Alert from '../components/Alert'
import Loader from '../components/Loader'
import { NavLink } from 'react-router-dom'

export const EditProfileScreen = () => {

  const { user, dispatch } = useContext(AuthContext)
  const [email, setEmail] = useState('')
  const [name, setName] = useState('')
  const [lastName, setLastName] = useState('')
  const [motherLastName, setMotherLastName] = useState('')
  const [formValidationMessage, setFormValidationMessage] = useState('')
  const [ok, setOk] = useState('')
  const [loading, setLoading] = useState(true)

  const clean = () => {
    setEmail('')
    setName('')
    setLastName('')
    setMotherLastName('')
    setFormValidationMessage('')
    setOk('')
  }

  const load = () => {
    document.title = 'Movo | Editar perfil'
    const options = {
      headers: { 'Authorization': `Bearer ${user.token}` }
    }
    axios.get('/edit_profile', options)
      .then(response => {
        setEmail(response.data.email)
        setName(response.data.name)
        setLastName(response.data.last_name)
        setMotherLastName(response.data.mother_last_name)
      })
      .catch(error => setFormValidationMessage(error.response.data))
      .finally(() => setLoading(false))
  }

  useEffect(load, [])

  const handleEnter = e => {
    if (e.key.toLowerCase() !== 'enter') return null
    const form = e.target.form
    const index = [...form].indexOf(e.target)
    form.elements[index + 1].focus()
    e.preventDefault()
  }

  const handleKeyDown = e => {
    if (e.key.toLowerCase() !== 'enter') return null
    updateUser()
    e.preventDefault()
  }

  const isValidForm = () => {
    let result = ''
    if (email.trim().length <= 0)
      result = 'Escriba un email válido'
    else if (name.trim().length <= 0)
      result = 'Escriba un nombre válido'
    else if (lastName.trim().length <= 0)
      result = 'Escriba un apellido válido'
    setFormValidationMessage(result)
    return !result
  }

  const updateUser = () => {
    setOk('')
    if (!isValidForm()) return null
    const params = {
      user: {
        email: email.trim(),
        name: name.trim(),
        last_name: lastName.trim(),
        mother_last_name: motherLastName.trim()
      }
    }
    const options = {
      headers: { 'Authorization': `Bearer ${user.token}` }
    }
    axios.put('/edit_profile', params, options)
      .then(response => {
        clean()
        load()
        const action = {
          type: types.editProfile,
          payload: {
            ...user,
            name: response.data.name,
            lastName: response.data.last_name,
            motherLastName: response.data.mother_last_name,
            fullName: response.data.full_name,
            email: response.data.email
          }
        }
        dispatch(action)
        setOk('Datos actualizados.')
      })
      .catch(error => (
        setFormValidationMessage('Ocurrió un error al intentar editar los datos.')
      ))
  }

  return (
    <>
      <Loader active={ loading } />
      <div className="l-settings header animate__animated animate__fadeIn">
        <NavLink id="changePasswordBack" className="back-button material-icons" to="/settings">arrow_back</NavLink>
        <form className="card" id="cardEditProfile">
          <header>
            <h1>Editar perfil</h1>
          </header>
          <div className="body">
            <div className="field">
              <input
                autoFocus
                type="email"
                placeholder="Email"
                value={email}
                onChange={e => setEmail(e.target.value)}
                onKeyDown={handleEnter}
              />
            </div>
            <div className="field">
              <input
                type="text"
                placeholder="Nombre"
                value={name}
                onChange={e => setName(e.target.value)}
                onKeyDown={handleEnter}
              />
            </div>
            <div className="field">
              <input
                type="text"
                placeholder="Primer apellido"
                value={lastName}
                onChange={e => setLastName(e.target.value)}
                onKeyDown={handleEnter}
              />
            </div>
            <div className="field">
              <input
                type="text"
                placeholder="Segundo apellido"
                value={motherLastName}
                onChange={e => setMotherLastName(e.target.value)}
                onKeyDown={handleKeyDown}
              />
            </div>
            <Alert show={formValidationMessage} value={formValidationMessage} />
            <Alert type={'info'} show={ok} value={ok} />
          </div>
          <footer>
            <button
              type="button"
              onKeyDown={handleKeyDown}
              onClick={updateUser}
            >
              Guardar
            </button>
          </footer>
        </form>
      </div>
    </>
  )
}
