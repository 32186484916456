const addCharEvery = (text, char, spaces) => {
  return text.split('').reduce((a, c, i) => {
    if (i % spaces === spaces - 1)
      return a + c + char
    return a + c
  }, '')
}
const clockFormat = x => x <= 9 ? `0${x}` : x

const toTimeOnlyformat = date => date.substring(11).substring(0, 5)
const toKmFormat = m => {
  const [integer, decimals] = (m / 1000).toString().split('.')
  const val = integer.split('').reverse().join('')
  const formatedString = addCharEvery(val, '\'', 3)
  const result = formatedString.split('').reverse().join('')
  return `${result}.${decimals} km`
}
const minutesToHours = m => {
  const hours = Math.floor(m / 60)
  const minutes = m % 60
  const postfix = hours === 0 ? 'min' : 'hr'
  const plural = (hours > 1 ? 's' : (minutes > 1 ? 's' : ''))
  return `${clockFormat(hours)}:${clockFormat(minutes)} ${postfix}${plural}`
}
const toLiters = l => `${l} lts`
const toSpeedFormat = s => {
  const val = s.toString().split('').reverse().join('')
  const result = addCharEvery(val, '\'', 3)
  return result.split('').reverse().join('') + ' km/h'
}

export {
  toTimeOnlyformat,
  toKmFormat,
  minutesToHours,
  toLiters,
  toSpeedFormat
}
