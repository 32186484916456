import { link, update } from '../../../services/ElocksScreen'

const SaveButton = ({
  id = '',
  token = '',
  params = {},
  macAddress = '',
  hasSelectedVehicle = false,
  macAddressWithoutChanges = '',
  setErrorMessage = '',
  clear = () => {},
  setLoading = () => {}
}) => {
  const isSameMacAddress = () => (
    id && hasSelectedVehicle && macAddress === macAddressWithoutChanges
  )

  const errorHandler = ({ error }) => setErrorMessage(error)

  const onSave = () => {
    if (!hasSelectedVehicle)
      return setErrorMessage('Debes seleccionar un vehículo')
    setLoading(true)
    if (!id) {
      link(token, params)
        .then(clear)
        .catch(errorHandler)
        .finally(() => setLoading(false))
      return
    }
    update(token, params, id)
      .then(clear)
      .catch(errorHandler)
      .finally(() => setLoading(false))
  }

  return (
    <button
      type='button'
      className='save-btn'
      onClick={ onSave }
    >
      { isSameMacAddress() ? 'Guardar' : 'Vincular' }
    </button>
  )
}

export { SaveButton }
