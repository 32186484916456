import { useState } from 'react'
import { isValidEmail } from '../../helpers/email_validator'

export const EmailInput = ({ emailList = '', setEmailList = () => {} }) => {
  const [currentEmail, setCurrentEmail] = useState('')
  const [wrongEmail, setWrongEmail] = useState(false)

  const emailListAsArray = () => emailList.split(';').filter(e => e)

  const isAlreadyInList = email => emailList.includes(email)

  const handleEnter = e => {
    setWrongEmail(false)
    if (e.key.toLowerCase() !== 'enter') return null
    if (!isValidEmail(currentEmail)) return setWrongEmail(true)
    if (isAlreadyInList(currentEmail)) return setWrongEmail(true)
    const newEmail = currentEmail.toLowerCase().trim()
    const list = emailList.split(';')
    const newList = [...list, newEmail].join(';')
    setEmailList(newList)
    setCurrentEmail('')
  }

  const removeEmail = email => {
    const newList = emailList.replace(email, '')
    const trimed = newList.replace(';;', ';')
    setEmailList(trimed.startsWith(';') ? trimed.substr(1, 1000) : trimed)
  }

  const getClasses = () => `field email-list-input ${wrongEmail ? 'wrong' : ''}`

  return (
    <div className={ getClasses() }>
      <label>Emails que recibirán notificaciones:</label>
      <ul>
        {
          emailListAsArray().map(e =>
            <li key={ e }>
              <label>{ e }</label>
              <i className="material-icons" onClick={ () => removeEmail(e) }>
                close
              </i>
            </li>
          )
        }
      </ul>
      <input
        type='email'
        placeholder='ejemplo@mail.com'
        value={ currentEmail }
        onChange={ e => setCurrentEmail(e.target.value) }
        onKeyDown={ handleEnter }
        tabIndex='9'
      />
    </div>
  )
}
