import CompassIcon from './CompassIcon'
import FilterIcon from './FilterIcon'
import StandstillIcon from './StandstillIcon'
import InactiveIcon from './InactiveIcon'
import { webfleetToDate, timeInMinutesSince } from '../../helpers/date_helpers.js'

const VehicleStatus = ({ vehicle={} }) => {

  const isDriving = () => vehicle.ignition === 1 && vehicle.standstill === 0
  const isStandstill = () => vehicle.standstill === 1
  const isInactive = () => {
    if (!vehicle.pos_time) return false
    const minutes = timeInMinutesSince(webfleetToDate(vehicle.pos_time))
    return minutes > 60 * 24 * 5
  }

  if (isInactive()) return (<InactiveIcon />)
  if (isDriving()) return (<CompassIcon course={ vehicle.course } />)
  if (isStandstill()) return (<StandstillIcon ignition={ vehicle.ignition } />)
  return (<></>)
}

export default VehicleStatus
