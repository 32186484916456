const InactiveIcon = () => {
  return (
    <svg
      enableBackground="new 0 0 48 48"
      height="22"
      version="1.1"
      viewBox="0 0 48 48"
      width="22"
      x="0px"
      y="0px"
    >
      <path d="M24.55,21.017h-8.435c-0.578,0-1.04,0.462-1.04,1.04l0,0c0,0.578,0.462,1.04,1.04,1.04h6.297L15.538,31.3c-0.347,0.404-0.52,0.924-0.462,1.329c0,0.578,0.462,1.04,1.04,1.04h8.377c0.578,0,1.04-0.462,1.04-1.04l0,0c0-0.578-0.462-1.04-1.04-1.04H18.08l6.99-8.377c0.231-0.231,0.347-0.52,0.404-0.809c0-0.058,0-0.116,0.058-0.173c0-0.058,0-0.058,0-0.116l0,0l0,0l0,0C25.59,21.017,24.724,21.017,24.55,21.017z"></path>
      <path d="M34.256,15.76h-6.297c-0.404,0-0.809,0.347-0.809,0.809l0,0c0,0.404,0.347,0.809,0.809,0.809h4.68l-5.142,6.124c-0.289,0.289-0.404,0.693-0.347,0.982c0,0.404,0.347,0.751,0.809,0.751h6.297c0.404,0,0.809-0.347,0.809-0.809l0,0c0-0.404-0.347-0.809-0.809-0.809h-4.795l5.257-6.239c0.173-0.173,0.289-0.404,0.289-0.635c0-0.058,0-0.058,0-0.116v-0.058l0,0l0,0l0,0C35.065,15.76,34.429,15.76,34.256,15.76z"></path>
      <path d="M24.261,10.503h-3.524c-0.231,0-0.462,0.173-0.462,0.462l0,0c0,0.231,0.173,0.462,0.462,0.462h2.6l-2.831,3.409c-0.173,0.173-0.231,0.404-0.173,0.52c0,0.231,0.173,0.404,0.462,0.404h3.524c0.231,0,0.462-0.173,0.462-0.462l0,0c0-0.231-0.173-0.462-0.462-0.462h-2.658l2.946-3.466c0.116-0.116,0.173-0.231,0.173-0.347v-0.058v-0.058l0,0l0,0l0,0C24.724,10.503,24.377,10.503,24.261,10.503z"></path>
    </svg>
  )
}

export default InactiveIcon
