import { useEffect } from 'react'

export const HomeScreen = () => {

  useEffect(() => {
    document.title = 'Movo'
  }, [])

  return (
    <>
      <div className="l-home header animate__animated animate__fadeIn">
        <img src="/movo-logo-full.png" width="300px" alt="logo"/>
      </div>
    </>
  )
}
