const handleEnter = e => {
  if (e.key.toLowerCase() !== 'enter') return null
  const form = e.target.form
  const index = [...form].indexOf(e.target)
  form.elements[index + 1].focus()
  e.preventDefault()
}

const handleKeyDown = (e, callback = () => {}) => {
  if (e.key.toLowerCase() !== 'enter') return null
  callback()
  e.preventDefault()
}

export { handleEnter, handleKeyDown }
