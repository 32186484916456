import { getUrlImg } from '../helpers/webfleet_img_helper'

const VehiclesCheckListItem = ({
  vehicle,
  onSelect=()=>{},
  onDeselect=()=>{},
  setSelectedVehicle=[]
}) => {

  const isSelectedVehicle = () =>
    setSelectedVehicle.map(i => i.objectuid).includes(vehicle.objectuid)

  const getClass = () =>
    `c-vehicles-check-list-item ${isSelectedVehicle() ? 'selected' : ''}`

  const handleOnClick = () => {
    if (isSelectedVehicle())
      onDeselect(vehicle)
    else
      onSelect(vehicle)
  }

  return (
    <li
      className={ getClass() }
      onClick={ handleOnClick }
    >
      <figure></figure>
      <div>
        <img alt="Vehículo" src={ getUrlImg(vehicle) } />
        <div>
          <h3>{vehicle.objectno} - {vehicle.objectname}</h3>
          <div>
            <span>{vehicle.speed} { vehicle.msgtime }, { vehicle.postext }</span>
          </div>
        </div>
      </div>
    </li>
  )
}

export default VehiclesCheckListItem
