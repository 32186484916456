import { handleEnter } from '../../helpers/forms'
import { Switch } from '../../components/Switch'

export const CanAccessSwitch = ({ canAccess = '', setCanAccess = () => {} }) => {
  return (
    <div className='field'>
      <Switch
        name='chkCanAccess'
        title='Permitir acceso al API de Webfleet a través de esta cuenta.'
        value={ canAccess }
        onChange={ e => setCanAccess(e.target.checked) }
        onKeyDown={ handleEnter }
        tabIndex='5'
      />
    </div>
  )
}
