const FilterIcon = () => {
  return (
    <svg
      enableBackground="new 0 0 80 80"
      height="18"
      version="1.1"
      viewBox="0 0 80 80"
      width="18"
      x="0px"
      y="0px"
    >
      <path d="M73,18.6c0.5,1.3,0.3,2.4-0.7,3.3L46.7,40v27c0,1.3-0.6,2.3-1.8,2.8C44.4,69.9,44,70,43.6,70c-0.9,0-1.6-0.3-2.1-0.9l-7.3-10.3c-0.6-0.6-0.9-1.3-0.9-2.1V40L7.6,21.9c-0.9-0.9-1.2-2-0.7-3.3c0.5-1.2,1.5-1.8,2.8-1.8h60.5C71.6,16.7,72.5,17.3,73,18.6L73,18.6z"></path>
    </svg>
  )
}

export default FilterIcon
