import { useContext, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { AuthContext } from "../auth/authContext"
import { types } from "../types/types"
import axios from '../helpers/api'
import CheckList from '../components/CheckList'

export const SettingsScreen = () => {

  const { user, dispatch } = useContext(AuthContext)
  const navigate = useNavigate()
  const [webfleetAccounts, setWebfleetAccounts] = useState([])

  useEffect(() => {
    document.title = 'Movo | Opciones'
    const load = async () => {
      try {
        const options = {
          headers: { 'Authorization': `Bearer ${user.token}` }
        }
        const result = await axios.get('/webfleet_accounts_by_token', options)
        setWebfleetAccounts(result.data)
      }
      catch (e) {
        console.error(e.message)
      }
    }
    load()
  }, [])

  const handleLogout = () => {
    dispatch({ type: types.logout })
    navigate('/login', { replace: true })
  }

  const handleEditProfile = () => navigate('/settings/edit_profile', { replace: true })
  const handleChangePassword = () => navigate('/settings/change_password', { replace: true })

  const selectWebfleetAccount = webfleetAccount => {
    const action = {
      type: types.selectAccount, payload: { ...user, selectedAccount: webfleetAccount }
    }
    dispatch(action)
  }

  return (
    <>
      <div className="l-settings header animate__animated animate__fadeIn">
        <h1>Opciones</h1>
        <div className="options">
          <div className="card">
            <h1>Perfil</h1>
            <div className="field">
              <label>Email: </label>
              <div>{user.email}</div>
            </div>
            <div className="field">
              <label>Nombre: </label>
              <div>{user.fullName}</div>
            </div>
            <footer>
              <button onClick={ handleLogout }>Cerrar sesión</button>
              <button onClick={ handleEditProfile }>Editar perfil</button>
              <button onClick={ handleChangePassword }>Cambiar contraseña</button>
            </footer>
          </div>
          <div className="card">
            <h1>Cuenta de Webfleet seleccionada</h1>
            <CheckList
              items={ webfleetAccounts }
              onSelectItem={ selectWebfleetAccount }
              setSelectedItem={ user.selectedAccount }
            />
          </div>
        </div>
      </div>
    </>
  )
}
