import { handleEnter } from '../../helpers/forms'
import { Switch } from '../../components/Switch'

export const VisibleInInrouteSessionsSwitch = ({ visibleInInrouteSessions = '', setVisibleInInrouteSessions = () => {} }) => {
  return (
    <div className='field'>
      <Switch
        name='chkVisibleInInrouteSessions'
        title='Ocultar esta cuenta para todos los usuarios.'
        value={ visibleInInrouteSessions }
        onChange={ e => setVisibleInInrouteSessions(e.target.checked) }
        onKeyDown={ handleEnter }
        tabIndex='6'
      />
    </div>
  )
}
