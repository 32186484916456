const VehicleGroupIcon = () => {
  return (
    <svg 
      className="svg-icon-module__svg___Gd7iB svg-icon-module__default-color___zK2Yx t3sel-vehicles-icon" 
      enableBackground="new 0 0 48 48" 
      height="18" 
      version="1.1" 
      viewBox="0 0 48 48" 
      width="18" 
      x="0px" 
      y="0px"
    >
      <path d="M44.467,31.315l-.97-.969a3.767,3.767,0,0,1-.767-1.24l-1.814-5.437a4.439,4.439,0,0,0-3.949-2.843h-12a4.439,4.439,0,0,0-3.949,2.843L19.2,29.106a3.764,3.764,0,0,1-.767,1.24l-.97.969a7.3,7.3,0,0,0-1.5,4.5V44.8a1.5,1.5,0,0,0,1.5,1.5h3a1.5,1.5,0,0,0,1.5-1.5V43.3h18v1.5a1.5,1.5,0,0,0,1.5,1.5h3a1.5,1.5,0,0,0,1.5-1.5V35.811A7.293,7.293,0,0,0,44.467,31.315Zm-24.75,7.492a2.248,2.248,0,1,1,2.25-2.248A2.249,2.249,0,0,1,19.717,38.808Zm16.5,1.5h-10.5a.749.749,0,1,1,0-1.5h10.5a.749.749,0,1,1,0,1.5ZM21.3,29.817a.507.507,0,0,1-.513-.711l1.656-4.963a2.971,2.971,0,0,1,2.526-1.818h12a2.971,2.971,0,0,1,2.526,1.818l1.656,4.963a.507.507,0,0,1-.513.711Zm20.919,8.991a2.248,2.248,0,1,1,2.25-2.248A2.249,2.249,0,0,1,42.217,38.808Z"></path>
      <path d="M18.172,22.719a6.41,6.41,0,0,1,.407-.939H11.717a.749.749,0,1,1,0-1.5h7.939a7.4,7.4,0,0,1,5.311-2.455h1.021a2.238,2.238,0,0,1,4.458,0h1.521v-.542a7.293,7.293,0,0,0-1.5-4.5l-.97-.969a3.767,3.767,0,0,1-.767-1.241L26.915,5.143A4.439,4.439,0,0,0,22.967,2.3h-12A4.439,4.439,0,0,0,7.018,5.143L5.2,10.58a3.763,3.763,0,0,1-.767,1.241l-.97.969a7.3,7.3,0,0,0-1.5,4.5v8.991a1.5,1.5,0,0,0,1.5,1.5h3a1.5,1.5,0,0,0,1.5-1.5v-1.5h9.52ZM6.785,10.58,8.441,5.617A2.97,2.97,0,0,1,10.967,3.8h12a2.97,2.97,0,0,1,2.526,1.818l1.656,4.963a.507.507,0,0,1-.513.711H7.3A.507.507,0,0,1,6.785,10.58Zm-1.068,9.7a2.248,2.248,0,1,1,2.25-2.248A2.249,2.249,0,0,1,5.717,20.281Z"></path>
    </svg>
  )
}

export default VehicleGroupIcon
