import FilterIcon from '../icons/FilterIcon'
import VehicleGroupIcon from '../icons/VehicleGroupIcon'

const FilterInputs = ({
  setGroupOpen = () => {},
  groupName = '',
  groupOpen,
  groups = [],
  handleSelectGroup = () => {},
  txtSearch = '',
  handleChangeTxtSearch = () => {}
}) => {

  const getGroupClass = () => `filter-bar-group-box ${groupOpen ? 'open' : ''}`

  return (
    <div className='filter'>
      <div className='filter-bar-group'>
        <label onClick={ () => setGroupOpen(true) }>
          <VehicleGroupIcon />
          { groupName }
        </label>
        <div className={ getGroupClass() }>
          {
            groups.map(g => (
              <label key={ g.id } onClick={ () => handleSelectGroup(g) }>
                { g.label }
              </label>
            ))
          }
        </div>
      </div>
      <div className='filter-bar-vehicle'>
        <input
          type='text'
          placeholder='Buscar'
          value={ txtSearch }
          onChange={ handleChangeTxtSearch }
        />
      </div>
      <div className='filter-bar-options'>
        <span>
          <FilterIcon />
        </span>
      </div>
    </div>
  )
}

export { FilterInputs }
