import { getUrlImg } from '../../helpers/webfleet_img_helper'
import { coordToDegrees, googleMapsLink } from '../../helpers/coords_helper'
import { webfleetToDate, timeInMinutesSince } from '../../helpers/date_helpers'

export const FullData = ({
  data=[],
  selectedGroup={ id: 'Todos' },
  groupRelations=[],
  okTimeValue=3,
  warningTimeValue=15
}) => {

  const minutesSince = v => {
    if (!v?.msgtime) return 1000000
    const date = webfleetToDate(v.msgtime)
    return timeInMinutesSince(date)
  }
  const okTime = v => minutesSince(v) <= okTimeValue
  const warningTime = v => minutesSince(v) <= warningTimeValue
  const sortedData = () =>
    filteredData().sort((a, b) => {
      return a.objectno.toUpperCase() > b.objectno.toUpperCase()
    })

  const filteredData = () => {
    if (selectedGroup.id === 'Todos') return data
    const vehiclesId = groupRelations
      .filter(g => g.objectgroupuid === selectedGroup.id)
      .map(g => g.objectuid)
    return data.filter(r => vehiclesId.includes(r.objectuid))
  }

  return (
    <div id="full-data" className="open l-connections">
      <div className="table">
        <div className="head">
          <div className="col img"></div>
          <div className="col date">Vehículo</div>
          <div className="col date">Placas</div>
          <div className="col date">Descripción</div>
          <div className="col date">Fecha</div>
          <div className="col coord">Coordenadas</div>
          <div className="col date">Dirección</div>
          <div className="col icon">Velocidad</div>
          <div className="col icon">Ignición</div>
          <div className="col icon">Parado</div>
        </div>
        {
          data?.length ?
          sortedData(data).map(r => (
            <div className="row" key={ r.objectno }>
              <div className="col img">
                <img alt="vehículo" src={ getUrlImg(r) } />
              </div>
              <div className="col date">
                <span>{ r.objectno }/{ r.objectname }</span>
              </div>
              <div className="col date">
                <span>{ r.licenseplatenumber }</span>
              </div>
              <div className="col date">
                <span>{ r.description }</span>
              </div>
              <div className="col date">
                <span>{ r.msgtime }</span>
                {
                  okTime(r) ?
                    (<i className="material-icons ok" title="posicionamiento óptimo">warning</i>) :
                    (warningTime(r) ?
                      (<i className="material-icons warning" title="Precaución">warning</i>) :
                      (<i className="material-icons danger" title="Sin posicionamiento">warning</i>))
                }
              </div>
              <div className="col coord">
                <span>
                  { coordToDegrees(r.latitude_mdeg) }, { coordToDegrees(r.longitude_mdeg) }
                  <a
                    href={ googleMapsLink(r.latitude_mdeg, r.longitude_mdeg) }
                    target="_blank"
                    rel="noreferrer"
                    title="Ver en google maps"
                  >
                    <i className="material-icons">map</i>
                  </a>
                </span>
              </div>
              <div className="col date">
                <span>{ r.postext }</span>
              </div>
              <div className="col icon">
                <span>{ r.speed || 0 } km/h</span>
              </div>
              <div className="col icon">
                {
                  r.ignition === 1 ?
                  (<i className="material-icons ok" title="El vehículo se encuentra encendido">check</i>) :
                  (<i className="material-icons danger" title="El vehículo se encuentra apagado">close</i>)
                }
              </div>
              <div className="col icon">
                {
                  r.standstill === 1 ?
                  (<i className="material-icons ok" title="El vehículo está parado">check</i>) :
                  (<i className="material-icons danger" title="El vehículo está en movimiento">close</i>)
                }
              </div>
            </div>
          )) :
          <></>
        }
      </div>
    </div>
  )
}
