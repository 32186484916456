import axios from '../helpers/api'

const getErrorMsg = errorResponse => {
  const msg = errorResponse?.response?.data[0] || 'Ocurrió un error, vuelve a intentarlo'
  return { error: msg }
}

const buildHeader = token => (
  { headers: { 'Authorization': `Bearer ${token}` } }
)

const getElock = async (token, objectuid) => {
  const url = `/elocks/${objectuid}`
  const { data } = await axios.get(url, buildHeader(token))
  return data
}

const link = async (token, params) => {
  try {
    const { data } = await axios.post('/elocks', params, buildHeader(token))
    return data
  } catch (error) {
    throw getErrorMsg(error)
  }
}

const update = async (token, params, id) => {
  try {
    const url = `/elocks/${id}`
    const { data } = await axios.put(url, params, buildHeader(token))
    return data
  } catch (error) {
    throw getErrorMsg(error)
  }
}

const destroy = async (token, id) => {
  try {
    const url = `/elocks/${id}`
    await axios.delete(url, buildHeader(token))
  } catch (error) {
    return getErrorMsg(error)
  }
}

export { getElock, link, update, destroy }
