import { toKmFormat } from "../../helpers/string_format_helpers"

const googleMapsUrl = 'https://www.google.com/maps/dir/'

export const FullData = ({ data=[] }) => {

  const getPosition = p => `${coordToDegrees(p.latitude)},${coordToDegrees(p.longitude)}`
  const speedFormat = p => `${p.speed} km/h`
  const coordToDegrees = coord => coord / 1000000
  const isThisPosition = (p1, p2) =>
    p1.latitude === p2.latitude && p1.longitude === p2.longitude
  const googleMapsLink = r => {
    const index = data.findIndex(p => isThisPosition(p, r))
    const dots = data
      .filter((p, i) => Math.abs(i - index) <= 12)
      .map((p, i) => `${getPosition(p)}`)
      .reverse()
      .join('/') + `/@${getPosition(data[index])},14z`
    return `${googleMapsUrl}${dots}`
  }

  return (
    <div id="full-data" className='open l-position-log'>
      <h1>Bitácora de posiciones</h1>
      <div className="table">
        <div className="head">
          <div className="col date">Fecha</div>
          <div className="col coord">Posición</div>
          <div className="col date">Velocidad</div>
          <div className="col date">Odómetro</div>
        </div>
        {
          data?.length ?
          data.map(r => (
            <div className="row" key={ r.pos_time }>
              <div className="col date">
                <span>{ r.pos_time }</span>
              </div>
              <div className="col coord">
                <span>
                  { getPosition(r) }
                  <a
                    href={ googleMapsLink(r) }
                    target="_blank"
                    rel="noreferrer"
                    title="Ver en google maps"
                  >
                    <i className="material-icons">map</i>
                  </a>
                </span>
              </div>
              <div className="col date">
                <span>{ speedFormat(r) }</span>
              </div>
              <div className="col date">
                <span>{ toKmFormat(r.odometer) }</span>
              </div>
            </div>
          )) :
          <></>
        }
      </div>
    </div>
  )
}
