import { useEffect } from 'react'
import axios from '../helpers/api'
import { useParams } from 'react-router-dom'

const ElockCommandExecutionScreen = () => {
  const { action, token } = useParams()

  const load = () => {  
    const url = `/elocks/${action}`
    axios.post(url, { token })
      .then(console.log)
      .catch(console.error)
      .finally(window.close)
  }
  
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(load, [])

  return <></>
}

export { ElockCommandExecutionScreen }
