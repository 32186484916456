import { useContext, useEffect, useState } from "react"
import { NavLink, useNavigate } from 'react-router-dom'
import { AuthContext } from "../auth/authContext"
import axios from '../helpers/api'
import CheckList from '../components/CheckList'
import Loader from '../components/Loader'

export const AccountRelationsSetScreen = () => {

  const { user } = useContext(AuthContext)
  const navigate = useNavigate()
  const [webfleetAccounts, setWebfleetAccounts] = useState([])
  const [loading, setLoading] = useState(true)
  const [selectedUser, setSelectedUser] = useState({ email: '' })
  const [selectedAccounts, setSelectedAccounts] = useState([])

  const idUserFromPath = () => window.location.pathname.split('/').reverse()[0]

  useEffect(() => {
    document.title = 'Movo | Cuentas de Webfleet por usuario'
    const load = async () => {
      try {
        const options = {
          headers: { 'Authorization': `Bearer ${user.token}` }
        }
        const result = await axios.get('/webfleet_accounts_visible_only', options)
        setWebfleetAccounts(result.data)
        const { data } = await axios.get(`/users/${idUserFromPath()}`, options)
        setSelectedUser(data)
        const accountsRelated = await axios.get(`webfleet_accounts_by_user_id/${idUserFromPath()}`, options)
        setSelectedAccounts(accountsRelated.data || [])
      }
      catch (e) {
        console.error(e.message)
      }
      finally {
        setLoading(false)
      }
    }
    load()
  }, [user.token])

  const save = () => {
    setLoading(true)
    const options = {
      headers: { 'Authorization': `Bearer ${user.token}` }
    }
    const params = { ids: selectedAccounts.map(a => a.id) }
    console.log(params)
    axios.put(`/users_set_webfleet_accounts/${selectedUser.id}`, params, options)
      .then(() => navigate('/admin/users'))
      .catch(console.error)
      .finally(() => setLoading(false))
  }

  const handleSelectItem = item => {
    const cleanedArray = [...new Set([...selectedAccounts, item])]
    setSelectedAccounts(cleanedArray)
  }

  const handleDeselectItem = item => {
    const cleanedArray = selectedAccounts.filter(a => a.id !== item.id)
    setSelectedAccounts(cleanedArray)
  }

  return (
    <>
      <div className="l-admin header animate__animated animate__fadeIn">
        <Loader active={ loading } />
        <NavLink
          id="changePasswordBack"
          className="back-button material-icons"
          to="/admin/users"
        >
          arrow_back
        </NavLink>
        <form className="card" id="cardEditProfile">
          <header>
            <h1>Cuentas de webfleet asociadas al usuario</h1>
            <h1>{selectedUser.email}</h1>
          </header>
          <div className="body">
            <CheckList
              items={ webfleetAccounts }
              multiselect={ true }
              onSelectItem={ handleSelectItem }
              onDeselectItem={ handleDeselectItem }
              setSelectedItem={ selectedAccounts }
            />
          </div>
          <footer>
            <button
              type="button"
              onClick={ save }
            >
              Guardar
            </button>
          </footer>
        </form>
      </div>
    </>
  )
}
