import React from 'react'

export const HeaderCardMainItem = ({
  title, 
  quantity, 
  measureUnit = '', 
  danger = false,
  extraClass = ''
}) => {
  const getClassName = () => `${extraClass} ${(danger ? 'danger' : '')}`

  return (
    <div className={ getClassName() }>
      <h2>{ title }</h2>
      <hgroup>
        <h1>{ quantity }</h1>
        <h3>{ measureUnit }</h3>
      </hgroup>
    </div>
  )
}
