const NameInput = ({ name, setName, setErrorMessage }) => {
  const nameHandler = ({ target: { value } }) => {
    setName(value)
    setErrorMessage('')
  }

  return (
    <div className='field'>
      <input
        type='text'
        value={ name }
        onChange={ nameHandler }
        placeholder='Nombre'
      />
    </div>
  )
}

export { NameInput }
