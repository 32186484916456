import { handleEnter } from '../../helpers/forms'

export const ApikeyInput = ({ apikey = '', setApikey = () => {} }) => {
  return (
    <div className='field'>
      <input
        type='text'
        placeholder='API key'
        value={ apikey }
        onChange={ e => setApikey(e.target.value) }
        onKeyDown={ handleEnter }
        tabIndex='4'
      />
    </div>
  )
}
