import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import {
  ElockCommandExecutionScreen
} from '../screens/ElockCommandExecutionScreen'
import { LoginScreen } from '../screens/LoginScreen'
import { DashboardRoutes } from './DashboardRouter'
import { PrivateRoute } from './PrivateRoute'
import { PublicRoute } from './PublicRoute'

export const AppRouter = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/login' element={
          <PublicRoute>
            <LoginScreen />
          </PublicRoute>
        } />
        <Route path='/elock/:action/:token' element={
          <ElockCommandExecutionScreen />
        } />
        <Route path='/*' element={
          <PrivateRoute>
            <DashboardRoutes />
          </PrivateRoute>
        } />
      </Routes>
    </BrowserRouter>
  )
}
