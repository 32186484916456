import { useEffect } from 'react'
import { Link } from 'react-router-dom'

export const AdminScreen = () => {

  useEffect(() => {
    document.title = 'Movo | Opciones de administrador'
  }, [])

  return (
    <div className="l-admin header animate__animated animate__fadeIn">
      <div className="row">
        <Link className="big-button" to="/admin/users">
          <i className="material-icons">person</i>
          <label>Usuarios</label>
        </Link>
        <Link className="big-button" to="/admin/webfleet_accounts">
          <i className="material-icons">account_tree</i>
          <label>Cuentas de Webfleet</label>
        </Link>
      </div>
    </div>
  )
}
