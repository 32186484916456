import { useContext, useState, useEffect } from 'react'
import { AuthContext } from '../auth/authContext'
import axios from '../helpers/api'
import Alert from '../components/Alert'
import Loader from '../components/Loader'
import ModalQuestion from '../components/ModalQuestion'
import { DropDown } from '../components/DropDown'
import { NavLink, useNavigate } from 'react-router-dom'

export const UserEditScreen = () => {

  const navigate = useNavigate()
  const { user } = useContext(AuthContext)
  const [roles, setRoles] = useState('')
  const [id, setId] = useState(0)
  const [email, setEmail] = useState('')
  const [name, setName] = useState('')
  const [lastName, setLastName] = useState('')
  const [motherLastName, setMotherLastName] = useState('')
  const [roleId, setRoleId] = useState(0)
  const [formValidationMessage, setFormValidationMessage] = useState('')
  const [ok, setOk] = useState('')
  const [loading, setLoading] = useState(true)
  const [showModal, setShowModal] = useState(false)

  const loadingData = async () => {
    const options = {
      headers: { 'Authorization': `Bearer ${user.token}` }
    }
    setLoading(true)
    try {
      const responseRoles = await axios.get('/roles', options)
      setRoles(mapRoles(responseRoles.data))
      const { data } = await axios.get(`/users/${idUserFromPath()}`, options)
      setId(data.id)
      setEmail(data.email)
      setName(data.name)
      setLastName(data.last_name)
      setMotherLastName(data.mother_last_name)
      setRoleId(data.role.id)
    }
    catch (e) {
      navigate('/admin/users')
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    document.title = 'Movo | Editar usuario'
    loadingData()
  }, [])

  const idUserFromPath = () => window.location.pathname.split('/').reverse()[0]

  const mapRoles = roles => roles
    .map(r => ({ id: r.id, value: r.description, label: r.description }))

  const handleEnter = e => {
    if (e.key.toLowerCase() !== 'enter') return null
    const form = e.target.form
    const index = [...form].indexOf(e.target)
    form.elements[index + 1].focus()
    e.preventDefault()
  }

  const handleKeyDown = e => {
    if (e.key.toLowerCase() !== 'enter') return null
    editUser()
    e.preventDefault()
  }

  const isValidForm = () => {
    let result = ''
    if (email.trim().length < 1)
      result = 'Escriba un email válido'
    else if (name.trim().length < 1)
      result = 'Escriba un nombre válido'
    else if (lastName.trim().length < 1)
      result = 'Escriba un apellido válido'
    else if (roleId < 1)
      result = 'Seleccione los privilegios del usuario'
    setFormValidationMessage(result)
    return !result
  }

  const editUser = () => {
    if (!isValidForm()) return null
    setOk('')
    setLoading(true)
    const params = {
      user: {
        email: email.trim(),
        name: name.trim(),
        last_name: lastName.trim(),
        mother_last_name: motherLastName.trim(),
        role_id: roleId
      }
    }
    const options = {
      headers: { 'Authorization': `Bearer ${user.token}` }
    }
    axios.put(`/users/${id}`, params, options)
      .then(response => (
        navigate('/admin/users')
      ))
      .catch(error => {
        if (error?.response?.data?.join)
          setFormValidationMessage(error.response.data.join('. '))
        else if (error?.response?.data?.user?.join)
          setFormValidationMessage(error.response.data.user.join('. '))
        else if (error?.message)
          setFormValidationMessage(error.message)
        else
          setFormValidationMessage(JSON.stringify(error))
      })
      .finally(() => setLoading(false))
  }

  const restarPassword = () => setShowModal(true)

  const handleRestartPassword = () => {
    const options = {
      headers: { 'Authorization': `Bearer ${user.token}` }
    }
    axios.put(`/reset_password/${id}`, {}, options)
      .then(() => navigate('/admin/users'))
      .catch(error => setFormValidationMessage(error.message))
  }

  return (
    <>
      <div className="l-admin header animate__animated animate__fadeIn">
        <Loader active={ loading } />
        <ModalQuestion
          title="Reinicio de contraseña"
          question="Al realizar esta acción se generará una nueva contraseña aleatoria y se enviará al email del usuario ¿Está seguro que desea reiniciar la contraseña?"
          active={showModal}
          onConfirm={handleRestartPassword}
          onCancel={() => setShowModal(false)} />
        <NavLink
          id="changePasswordBack"
          className="back-button material-icons"
          to="/admin/users"
        >
          arrow_back
        </NavLink>
        <form className="card" id="cardEditProfile">
          <header>
            <h1>Edición de usuario</h1>
          </header>
          <div className="body">
            <div className="field">
              <input
                autoFocus
                type="email"
                placeholder="Email"
                value={email}
                onChange={e => setEmail(e.target.value)}
                onKeyDown={handleEnter}
                tabIndex="1"
              />
            </div>
            <div className="field">
              <input
                type="text"
                placeholder="Nombre"
                value={name}
                onChange={e => setName(e.target.value)}
                onKeyDown={handleEnter}
                tabIndex="2"
              />
            </div>
            <div className="field">
              <input
                type="text"
                placeholder="Primer apellido"
                value={lastName}
                onChange={e => setLastName(e.target.value)}
                onKeyDown={handleEnter}
                tabIndex="3"
              />
            </div>
            <div className="field">
              <input
                type="text"
                placeholder="Segundo apellido"
                value={motherLastName}
                onChange={e => setMotherLastName(e.target.value)}
                onKeyDown={handleEnter}
                tabIndex="4"
              />
            </div>
            <div className="field">
              <DropDown
                placeholder="Privilegios"
                list={roles}
                value={roleId}
                onSelect={role => setRoleId(role.id)}
                onKeyDown={handleEnter}
                tabIndex="5"
              />
            </div>
            <Alert show={formValidationMessage} value={formValidationMessage} />
            <Alert type={'info'} show={ok} value={ok} />
          </div>
          <footer>
            <button
              type="button"
              onClick={restarPassword}
              tabIndex="7"
            >
              Reiniciar contraseña
            </button>
            <button
              type="button"
              onKeyDown={handleKeyDown}
              onClick={editUser}
              tabIndex="6"
            >
              Guardar
            </button>
          </footer>
        </form>
      </div>
    </>
  )
}
