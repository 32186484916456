const Header = ({ selectedVehicle = {} }) => {
  const hasSelectedVehicle = () => selectedVehicle.objectname

  const getVehicleName = () => {
    if (!hasSelectedVehicle()) return 'Selecciona un vehículo'
    return `${selectedVehicle.objectno} - ${selectedVehicle.objectname}`
  }

  return (
    <header className='selected-vehicle'>
      <label>{ getVehicleName() }</label>
    </header>
  )
}

export { Header }
