import React from 'react'
import { HeaderCardItem } from './HeaderCardItem'
import { HeaderCardMainItem } from './HeaderCardMainItem'
import { timeConvert } from '../../components/DateTimePicker'

export const Header = ({ data }) => {

  const headers = () => [
    { order: 1, title: 'Recorrido',                 quantity: getTotalDistance(),       measureUnit: 'km',    danger: false },
    { order: 2, title: 'Rendimiento',               quantity: getTotalPerformance(),    measureUnit: 'km/lt', danger: false },
    { order: 3, title: 'Total combustible',         quantity: getTotalFuel(),           measureUnit: 'lts',   danger: false },
    { order: 4, title: 'Duración total',            quantity: getTotalDuration(),       measureUnit: 'hrs',   danger: false },
    { order: 5, title: 'Duración de ralentí',       quantity: getTotalIdleTime(),       measureUnit: 'hrs',   danger: false },
    { order: 6, title: 'Total combustible ralentí', quantity: getTotalIdleFuel(),       measureUnit: 'lts',   danger: false },
    { order: 7, title: 'Frenadas bruscas',          quantity: getTotalBrakingEvents(),  measureUnit: '',      danger: false },
    { order: 8, title: 'Vueltas bruscas',           quantity: getTotalSteeringEvents(), measureUnit: '',      danger: false },
    { order: 9, title: 'Excesos de velocidad',      quantity: getTotalSpeedingEvents(), measureUnit: '',      danger: false }
  ]

  const first = () => headers().find(h => h.order === 1)
  const second = () => headers().find(h => h.order === 2)
  const third = () => headers().find(h => h.order === 3)
  const rest = () => headers().filter(h => !([1, 2, 3].includes(h.order)))

  const sum = (a, b) => a + b

  const getTotalDistance = () => {
    const distance = data
      ?.showTripReportExtern?.map(x => x.distance).reduce(sum, 0) || 0
    return distance / 1000
  }

  const getTotalFuel = () => (
    data?.showTripReportExtern?.map(x => x.fuel_usage).reduce(sum, 0) || 0
  )

  const getTotalPerformance = () => {
    if (getTotalFuel() <= 0) return 0
    return getTotalDistance() / getTotalFuel()
  }

  const getTotalDuration = () => {
    const duration = data
      ?.showTripReportExtern?.map(x => x.duration).reduce(sum, 0) || 0
    return timeConvert(duration)
  }

  const getTotalIdleTime = () => {
    const duration = data
      ?.showTripReportExtern?.map(x => x.idle_time).reduce(sum, 0) || 0
    return timeConvert(duration)
  }

  const getTotalIdleFuel = () => {
    if (data?.showIdleExceptions === undefined) return 0
    if (data.showIdleExceptions.errorCode === 9004 ) return 0
    return data.showIdleExceptions.map(x => x.fuel_usage).reduce(sum, 0) || 0
  }

  const getTotalBrakingEvents = () => (
    data?.getObjectKPIs?.[0]?.drivingevents_braking_count || 0
  )

  const getTotalSteeringEvents = () => (
    data?.getObjectKPIs?.[0]?.drivingevents_steering_count || 0
  )

  const getTotalSpeedingEvents = () => (
    data?.getObjectKPIs?.[0]?.speedingevents_count || 0
  )

  return (
    <div className="header animate__animated animate__fadeIn">
      <h1>Estadísticas de viajes por vehículo</h1>
      <div className="body">
        <div className="col-main">
          <HeaderCardMainItem
            title={first().title}
            quantity={ first().quantity }
            measureUnit={first().measureUnit}
            danger={first().danger}
            extraClass="first"
          />
          <div className="more">
            <HeaderCardMainItem
              title={second().title}
              quantity={ second().quantity }
              measureUnit={second().measureUnit}
              danger={second().danger}
              extraClass="second"
            />
            <HeaderCardMainItem
              title={third().title}
              quantity={ third().quantity }
              measureUnit={third().measureUnit}
              danger={third().danger}
              extraClass="third"
            />
          </div>
        </div>
        <div className="col-second">
          {
            rest().map(i => (
              <HeaderCardItem
                key={i.order}
                title={i.title}
                quantity={i.quantity}
                measureUnit={i.measureUnit}
                danger={i.danger}
                extraClass={i.extraClass}
              />)
            )
          }
        </div>
      </div>
    </div>
  )
}
