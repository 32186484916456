import { handleEnter } from '../../helpers/forms'
import { Switch } from '../../components/Switch'

export const ElockSwitch = ({ isUsingElock = '', setIsUsingELock = () => {} }) => {
  return (
    <div className='field'>
      <Switch
        name='checkElockUse'
        title='Usa dispositivos Elock.'
        value={ isUsingElock }
        onChange={ e => setIsUsingELock(e.target.checked) }
        onKeyDown={ handleEnter }
        tabIndex='7'
      />
    </div>
  )
}
