import VehiclesCheckListItem from './VehiclesCheckListItem'

const VehiclesCheckList = ({
  vehicles=[],
  onSelect=()=>{},
  onDeselect=()=>{},
  setSelectedVehicle=[]
}) => {
  return (
    <ul className="c-vehicles-check-list">
      {
        vehicles.map(v => (
          <VehiclesCheckListItem
            key={ v.objectno }
            vehicle={ v }
            onSelect={ onSelect }
            onDeselect={ onDeselect }
            setSelectedVehicle={ setSelectedVehicle }
          />
        ))
      }
    </ul>
  )
}

export default VehiclesCheckList
