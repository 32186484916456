import { useContext, useEffect, useState } from "react"
import { AuthContext } from "../auth/authContext"
import axios from '../helpers/api'
import Loader from '../components/Loader'
import { Link } from 'react-router-dom'
import ModalQuestion from '../components/ModalQuestion'

export const UsersScreen = () => {

  const { user } = useContext(AuthContext)
  const [usersList, setUsersList] = useState([])
  const [loading, setLoading] = useState(true)
  const [showModal, setShowModal] = useState(false)
  const [selectedUser, setSelectedUser] = useState({ email: '' })

  const load = () => {
    document.title = 'Movo | Usuarios'
    const options = {
      headers: { 'Authorization': `Bearer ${user.token}` }
    }
    axios.get('/users', options)
      .then(request => setUsersList(request.data))
      .catch(console.error)
      .finally(() => setLoading(false))
  }

  useEffect(() => { load() }, [])

  const fullName = u => `${u.last_name} ${u.mother_last_name} ${u.name}`

  const sortList = () => usersList.sort(
    (a, b) => fullName(a).toLowerCase() > fullName(b).toLowerCase()
  )

  const handleDeleteUser = () => {
    const options = {
      headers: { 'Authorization': `Bearer ${user.token}` }
    }
    axios.delete(`/users/${selectedUser.id}`, options)
      .then(response => {})
      .catch(console.error)
      .finally(() => {
        setShowModal(false)
        load()
      })
  }

  return (
    <>
      <Loader active={ loading } />
      <ModalQuestion
        title="Eliminar Usuario"
        question={`¿Está seguro que desea eliminar el usuario ${selectedUser.email}?`}
        active={showModal}
        onConfirm={handleDeleteUser}
        onCancel={() => setShowModal(false)} />
      <div className="l-admin header animate__animated animate__fadeIn">
        <div className="card">
          <header>
            <h1>Usuarios</h1>
            <Link className="btn" to="/admin/users/new">Nuevo usuario</Link>
          </header>
          <div className="body">
            <div className="table">
              <header>
                <div>Email</div>
                <div>Nombre</div>
                <div>Permisos</div>
                <div className="action"></div>
                <div className="action"></div>
                <div className="action"></div>
              </header>
              <div className="table-body">
                {
                  sortList().map(u => (
                    <div className="table-row" key={u.id}>
                      <div>{u.email}</div>
                      <div>{fullName(u)}</div>
                      <div>{u.role.description}</div>
                      <div className="action">
                        <a className="btn" href={`/admin/account_relations/${u.id}`}>Cuentas</a>
                      </div>
                      <div className="action">
                        <a className="btn" href={`/admin/users/edit/${u.id}`}>Editar</a>
                      </div>
                      <div className="action">
                        <button
                          type="button"
                          className="danger"
                          onClick={() => {
                            setSelectedUser(u)
                            setShowModal(true)
                          }}
                        >
                          Eliminar
                        </button>
                      </div>
                    </div>
                  ))
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
