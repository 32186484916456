import React, { useState, useEffect } from 'react'

export const DropDown = ({
  placeholder='',
  list=[],
  onSelect=i=>{},
  value=null,
  expandUp=false
}) => {

  const [selectedInput, setSelectedInput] = useState({ value: '' })
  const [open, setOpen] = useState(false)

  const handleClickItem = i => {
    setSelectedInput(i)
    setOpen(false)
    onSelect(i)
  }

  const handleClickLabel = l => setOpen(!open)

  useEffect(() => {
    if (!list) return () => {}
    const v = list.find(i => i.id === value) || { value: '' }
    setSelectedInput(v)
  }, [value, list])

  const getClases = () => {
    const o = open ? 'open' : ''
    const up = expandUp ? 'expand-up' : ''
    return `base-drop-down ${o} ${up}`
  }

  return (
    <div className={ getClases() }>
      <div className="input" onClick={ handleClickLabel }>
        <label className={ selectedInput.value ? 'with-input' : '' } >
          { selectedInput.value || placeholder }
        </label>
        <i className='material-icons'>arrow_drop_down</i>
      </div>
      {
        list.length ?
        <ul>
          {
            list.map(i => (
              <li key={ i.id } onClick={ () => handleClickItem(i) }>
                <label>
                  { i.label }
                </label>
              </li>
            ))
          }
        </ul> :
        <></>
      }
    </div>
  )
}
