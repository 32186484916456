import { useContext, useState, useEffect } from 'react'
import { AuthContext } from '../auth/authContext'
import axios from '../helpers/api'
import Alert from '../components/Alert'
import Loader from '../components/Loader'
import { NavLink, useNavigate } from 'react-router-dom'
import { handleKeyDown } from '../helpers/forms'
import { AccountInput } from '../components/webfleetAccountCrud/AccountInput'
import { UsernameInput } from '../components/webfleetAccountCrud/UsernameInput'
import { PasswordInput } from '../components/webfleetAccountCrud/PasswordInput'
import { ApikeyInput } from '../components/webfleetAccountCrud/ApikeyInput'
import { CanAccessSwitch } from '../components/webfleetAccountCrud/CanAccessSwitch'
import { VisibleInInrouteSessionsSwitch } from '../components/webfleetAccountCrud/VisibleInInrouteSessionsSwitch'
import { ElockSwitch } from '../components/webfleetAccountCrud/ElockSwitch'
import { EmailInput } from '../components/webfleetAccountCrud/EmailInput'

export const WebfleetAccountNewScreen = () => {
  const navigate = useNavigate()
  const { user } = useContext(AuthContext)

  const [account, setAccount] = useState('')
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [apikey, setApikey] = useState('')
  const [canAccess, setCanAccess] = useState(true)
  const [isUsingElock, setIsUsingELock] = useState(false)
  const [visibleInInrouteSessions, setVisibleInInrouteSessions] = useState(false)
  const [emailList, setEmailList] = useState('')

  const [formValidationMessage, setFormValidationMessage] = useState('')
  const [ok, setOk] = useState('')
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    document.title = 'Movo | Nueva cuenta de Webfleet'
  }, [])

  const hasValue = value => value.trim().length

  const isValidForm = () => {
    let result = ''
    if (!hasValue(account))      result = 'Escriba una cuenta válida'
    if (!hasValue(username)) result = 'Escriba un nombre de usuario válido'
    if (!hasValue(password))     result = 'Escriba una contraseña válida'
    if (!hasValue(apikey))       result = 'Escriba una API key válida'
    setFormValidationMessage(result)
    return !result
  }

  const buildParams = () => ({
    webfleet_account: {
      account: account.trim(),
      user: username.trim(),
      password: password.trim(),
      api_key: apikey.trim(),
      'can_access?': canAccess,
      'visible_in_inroute_sessions?': !visibleInInrouteSessions,
      'use_elock?': isUsingElock,
      email_list: emailList || null
    }
  })

  const buildHeaders = () => ({
    headers: { 'Authorization': `Bearer ${user.token}` }
  })

  const createAccount = () => {
    if (!isValidForm()) return null
    setOk('')
    setLoading(true)
    axios.post('/webfleet_accounts', buildParams(), buildHeaders())
      .then(() => navigate('/admin/webfleet_accounts'))
      .catch(error => {
        console.log(error)
        if (error?.response?.data?.join)
          setFormValidationMessage(error.response.data.join('. '))
        else if (error?.response?.data?.user?.join)
          setFormValidationMessage(error.response.data.user.join('. '))
        else
          setFormValidationMessage(error.message)
      })
      .finally(() => setLoading(false))
  }

  return (
    <div className='l-admin header animate__animated animate__fadeIn'>
      <Loader active={ loading } />
      <NavLink id='changePasswordBack' className='back-button material-icons' to='/admin/webfleet_accounts'>arrow_back</NavLink>
      <form className='card' id='cardEditProfile'>
        <header>
          <h1>Nueva cuenta de Webfleet</h1>
        </header>
        <div className='body'>
          <AccountInput account={ account } setAccount={ setAccount } />
          <UsernameInput username={ username } setUsername={ setUsername } />
          <PasswordInput password={ password } setPassword={ setPassword } />
          <ApikeyInput apikey={ apikey } setApikey={ setApikey } />
          <CanAccessSwitch canAccess={ canAccess } setCanAccess={ setCanAccess } />
          <VisibleInInrouteSessionsSwitch visibleInInrouteSessions={ visibleInInrouteSessions } setVisibleInInrouteSessions={ setVisibleInInrouteSessions } />
          <ElockSwitch isUsingElock={ isUsingElock } setIsUsingELock={ setIsUsingELock } />
          <EmailInput emailList={ emailList } setEmailList={ setEmailList } />
          <Alert show={ formValidationMessage } value={ formValidationMessage } />
          <Alert type='info' show={ ok } value={ ok } />
        </div>
        <footer>
          <button
            type='button'
            onKeyDown={ e => handleKeyDown(e, createAccount) }
            onClick={ createAccount }
            tabIndex='8'
          >
            Guardar
          </button>
        </footer>
      </form>
    </div>
  )
}
