import { useContext, useState, useEffect } from 'react'
import { AuthContext } from '../auth/authContext'
import axios from '../helpers/api'
import Alert from '../components/Alert'
import Loader from '../components/Loader'
import { NavLink, useNavigate } from 'react-router-dom'
import { handleKeyDown } from '../helpers/forms'
import { AccountInput } from '../components/webfleetAccountCrud/AccountInput'
import { UsernameInput } from '../components/webfleetAccountCrud/UsernameInput'
import { PasswordInput } from '../components/webfleetAccountCrud/PasswordInput'
import { ApikeyInput } from '../components/webfleetAccountCrud/ApikeyInput'
import { CanAccessSwitch } from '../components/webfleetAccountCrud/CanAccessSwitch'
import { VisibleInInrouteSessionsSwitch } from '../components/webfleetAccountCrud/VisibleInInrouteSessionsSwitch'
import { ElockSwitch } from '../components/webfleetAccountCrud/ElockSwitch'
import { EmailInput } from '../components/webfleetAccountCrud/EmailInput'

export const WebfleetAccountEditScreen = () => {
  const navigate = useNavigate()
  const { user } = useContext(AuthContext)

  const [id, setId] = useState(0)
  const [account, setAccount] = useState('')
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [apikey, setApikey] = useState('')
  const [canAccess, setCanAccess] = useState(true)
  const [isUsingElock, setIsUsingELock] = useState(false)
  const [visibleInInrouteSessions, setVisibleInInrouteSessions] = useState(false)
  const [emailList, setEmailList] = useState('')

  const [formValidationMessage, setFormValidationMessage] = useState('')
  const [ok, setOk] = useState('')
  const [loading, setLoading] = useState(false)

  const idFromPath = () => window.location.pathname.split('/').reverse()[0]

  const buildHeaders = () => ({
    headers: { 'Authorization': `Bearer ${user.token}` }
  })

  const setData = data => {
    setId(data.id)
    setAccount(data.account)
    setUsername(data.user)
    setCanAccess(data['can_access?'])
    setIsUsingELock(data['use_elock?'])
    setVisibleInInrouteSessions(!data['visible_in_inroute_sessions?'])
    setEmailList(data.email_list || '')
  }

  const loadingData = async () => {
    document.title = 'Movo | Editar cuenta de Webfleet'
    setLoading(true)
    try {
      const url = `/webfleet_accounts/${idFromPath()}`
      const { data } = await axios.get(url, buildHeaders())
      setData(data)
    }
    catch (e) {
      navigate('/admin/webfleet_accounts')
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    loadingData()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const hasValue = property => property.trim().length

  const isValidForm = () => {
    let result = ''
    if (!hasValue(account))      result = 'Escriba un nombre de cuenta válido'
    if (!hasValue(username)) result = 'Escriba un nombre de usuario válido'
    setFormValidationMessage(result)
    return !result
  }

  const buildParams = () => ({
    webfleet_account: {
      account: account.trim(),
      user: username.trim(),
      password: password.trim(),
      api_key: apikey.trim(),
      'can_access?': canAccess,
      'visible_in_inroute_sessions?': !visibleInInrouteSessions,
      'use_elock?': isUsingElock,
      email_list: emailList || null
    }
  })

  const removeEmptyProps = params => {
    if (!hasValue(password)) delete params.webfleet_account.password
    if (!hasValue(apikey))   delete params.webfleet_account.api_key
  }

  const editWebfleetAccount = () => {
    if (!isValidForm()) return null
    setOk('')
    setLoading(true)
    const params = buildParams()
    removeEmptyProps(params)
    const url = `/webfleet_accounts/${id}`
    axios.put(url, params, buildHeaders())
      .then(() => navigate('/admin/webfleet_accounts'))
      .catch(error => {
        if (error?.response?.data)
          setFormValidationMessage(error.response.data.join('\n'))
        else
          setFormValidationMessage(error.message)
      })
      .finally(() => setLoading(false))
  }

  return (
    <div className='l-admin header animate__animated animate__fadeIn'>
      <Loader active={ loading } />
      <NavLink
        id='changePasswordBack'
        className='back-button material-icons'
        to='/admin/webfleet_accounts'
      >
        arrow_back
      </NavLink>
      <form className='card' id='cardEditProfile'>
        <header>
          <h1>Edición de cuenta de Webfleet</h1>
        </header>
        <div className='body'>
          <AccountInput account={ account } setAccount={ setAccount } />
          <UsernameInput username={ username } setUsername={ setUsername } />
          <PasswordInput password={ password } setPassword={ setPassword } />
          <ApikeyInput apikey={ apikey } setApikey={ setApikey } />
          <CanAccessSwitch canAccess={ canAccess } setCanAccess={ setCanAccess } />
          <VisibleInInrouteSessionsSwitch visibleInInrouteSessions={ visibleInInrouteSessions } setVisibleInInrouteSessions={ setVisibleInInrouteSessions } />
          <ElockSwitch isUsingElock={ isUsingElock } setIsUsingELock={ setIsUsingELock } />
          <EmailInput emailList={ emailList } setEmailList={ setEmailList } />
          <Alert show={ formValidationMessage } value={ formValidationMessage} / >
          <Alert type='info' show={ ok } value={ ok } />
        </div>
        <footer>
          <button
            type='button'
            onKeyDown={ e => handleKeyDown(e, editWebfleetAccount) }
            onClick={ editWebfleetAccount }
            tabIndex='8'
          >
            Guardar
          </button>
        </footer>
      </form>
    </div>
  )
}
