import DatePicker, { registerLocale } from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import es from "date-fns/locale/es"
registerLocale("es", es)

const fill = x => x.toString().padStart(2, '0')

export const timeConvert = n => {
  const hours = Math.floor(n / 3600)
  const minutes = Math.floor( (n % 3600) / 60 )
  const seconds = n % 60
  return `${fill(hours)}:${fill(minutes)}:${fill(seconds)}`
}

export const toDateFormat = d => {
  const day     = fill(d.getDate())
  const month   = fill(d.getMonth() + 1)
  const year    = d.getFullYear()
  const hour    = fill(d.getHours())
  const minutes = fill(d.getMinutes())
  return `${day}/${month}/${year} ${hour}:${minutes}`
}

export const DateTimePicker = ({ id='', value=(new Date()), onChange=()=>{} }) => {
  return (
    <>
      <label className="c-date control" htmlFor={ id }>
        { toDateFormat(value) }
        <i className="material-icons">calendar_month</i>
      </label>
      <DatePicker
        id={ id }
        selected={ value }
        locale="es"
        showTimeSelect
        onChange={ onChange }
        dateFormat="dd/MM/yyyy HH:mm"
        className="react-datepicker-wrapper"
      />
    </>
  )
}
