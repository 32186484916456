const VehicleNotFoundIcon = () => {
  return (
    <svg
      enableBackground="new 0 0 48 48"
      height="80"
      version="1.1"
      viewBox="0 0 48 48"
      width="80" x="0px" y="0px"
    >
      <path d="M41.843,20.417,40.55,19.124a5.023,5.023,0,0,1-1.023-1.656l-2.419-7.257a5.919,5.919,0,0,0-5.265-3.795h-16a5.919,5.919,0,0,0-5.265,3.795L8.159,17.468a5.023,5.023,0,0,1-1.023,1.656L5.843,20.417c-1.1,1.1-2,3.791-2,6v12a2,2,0,0,0,2,2h4a2,2,0,0,0,2-2v-2h24v2a2,2,0,0,0,2,2h4a2,2,0,0,0,2-2v-12C43.843,24.207,42.948,21.521,41.843,20.417Zm-33,10a3,3,0,1,1,3-3A3,3,0,0,1,8.843,30.417Zm22,2h-14a1,1,0,0,1,0-2h14a1,1,0,1,1,0,2Zm-19.892-14a.677.677,0,0,1-.684-.949l2.208-6.624a3.961,3.961,0,0,1,3.367-2.427h16a3.961,3.961,0,0,1,3.367,2.427l2.208,6.624a.677.677,0,0,1-.684.949Zm27.892,12a3,3,0,1,1,3-3A3,3,0,0,1,38.843,30.417Z"></path>
    </svg>
  )
}

export default VehicleNotFoundIcon
