import React from 'react'
import { Link } from 'react-router-dom'
import { NavbarItem } from './NavbarItem'
import { NavbarSpace } from './NavbarSpace'
import { useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { AuthContext } from '../auth/authContext'
import { types } from '../types/types'
import axios from '../helpers/api'

export const Navbar = () => {

  const { user, dispatch } = useContext(AuthContext)
  const navigate = useNavigate()

  const handleLogout = (error) => {
    dispatch({ type: types.logout })
    navigate('/login', { replace: true })
  }

  useEffect(() => {
    const options = {
      headers: { 'Authorization': `Bearer ${user.token}` }
    }
    axios.get('check_token', options)
      .then(response => console.log('Session alive'))
      .catch(error => handleLogout(error))
  }, [user?.selectedAccount])

  const links = [
    { id: 1, name: 'Viajes', icon: 'map', path: '/metrics_by_vehicle' },
    // { id: 2, name: 'Telemetría', icon: 'satellite_alt', path: '/telemetrics' },
    { id: 3, name: 'Ubicaciones', icon: 'location_on', path: '/positions_log' },
    { id: 4, name: 'Conexiones', icon: 'leak_add', path: '/connections' },
    { id: 0 },
    { id: 7, name: 'Elocks', icon: 'settings_remote', path: '/elocks' },
    { id: 6, name: 'Admin', icon: 'admin_panel_settings', path: '/admin' },
    { id: 5, name: 'Opciones', icon: 'settings', path: '/settings' },
  ]

  const isElocksScreen = ({ name }) => name === 'Elocks'

  const hasSelectedAccountForElocks = () => user?.selectedAccount['use_elock?']

  const isAllowedScreenToSelectedAccount = screen => (
    !isElocksScreen(screen) ||
    (isElocksScreen(screen) && hasSelectedAccountForElocks())
  )

  const isAdminUser = () => [1, 2].includes(user?.role?.id)
  const isAllowedScreen = ({ id }) => ![6, 7].includes(id)

  const allowedScreens = screen => (
    (isAdminUser() && !isElocksScreen(screen)) ||
    (isAdminUser() && isAllowedScreenToSelectedAccount(screen)) ||
    (!isAdminUser() && isAllowedScreen(screen))
  )

  const filterLinks = () => links.filter(allowedScreens)

  return (
    <header id='main-menu' className='animate__animated animate__fadeInLeft'>
      <div className='logo'>
        <Link to='/'>
          <img src='../../../movo-logo.png' alt='Movo logo' />
        </Link>
      </div>
      <nav>
          <ul>
            {
              filterLinks().map(l => {
                return (l.id !== 0 ?
                  <NavbarItem
                    key={ l.id }
                    name={ l.name }
                    icon={ l.icon }
                    path={ l.path }
                  />
                : <NavbarSpace key={ l.id } />
              )})
            }
          </ul>
      </nav>
    </header>
  )
}
