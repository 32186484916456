import { useContext, useEffect, useState } from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'
import { AccountRelationsSetScreen } from '../screens/AccountRelationsSetScreen'
import { AdminScreen } from '../screens/AdminScreen'
import { AuthContext } from '../auth/authContext'
import { ChangePasswordScreen } from '../screens/ChangePasswordScreen'
import { ConnectionsScreen } from '../screens/ConnectionsScreen'
import { EditProfileScreen } from '../screens/EditProfileScreen'
import { ElocksScreen } from '../screens/ElocksScreen'
import { HomeScreen } from '../screens/HomeScreen'
import { MetricsByVehicleScreen } from '../screens/MetricsByVehicleScreen'
import { Navbar } from '../components/Navbar'
import { PositionLogScreen } from '../screens/PositionLogScreen'
import { SettingsScreen } from '../screens/SettingsScreen'
import { UsersScreen } from '../screens/UsersScreen'
import { UserNewScreen } from '../screens/UserNewScreen'
import { UserEditScreen } from '../screens/UserEditScreen'
import { WebfleetAccountsScreen } from '../screens/WebfleetAccountsScreen'
import { WebfleetAccountNewScreen } from '../screens/WebfleetAccountNewScreen'
import { WebfleetAccountEditScreen } from '../screens/WebfleetAccountEditScreen'

export const DashboardRoutes = () => {

  const { user } = useContext(AuthContext)
  const [loaded, setLoaded] = useState(false)
  useEffect(() => { setLoaded(true) }, [])

  const isAdmin = () => user?.role?.id < 3

  return (
    <>
      <Navbar />
      <div id='container'>
        <div className={ 'content ' + (loaded ? '' : 'hidden') }>
          <Routes>
            <Route path='/' element={ <HomeScreen /> } />
            <Route path='/metrics_by_vehicle' element={ <MetricsByVehicleScreen /> } />
            <Route path='/positions_log' element={ <PositionLogScreen /> } />
            <Route path='/connections' element={ <ConnectionsScreen /> } />
            {
              isAdmin() ?
              <>
                <Route path='/admin' element={ <AdminScreen /> } />
                <Route path='/admin/users' element={ <UsersScreen /> } />
                <Route path='/admin/users/new' element={ <UserNewScreen /> } />
                <Route path='/admin/users/edit/:id' element={ <UserEditScreen /> } />
                <Route path='/admin/webfleet_accounts' element={ <WebfleetAccountsScreen /> } />
                <Route path='/admin/webfleet_accounts/new' element={ <WebfleetAccountNewScreen /> } />
                <Route path='/admin/webfleet_accounts/edit/:id' element={ <WebfleetAccountEditScreen /> } />
                <Route path='/admin/account_relations/:id' element={ <AccountRelationsSetScreen /> } />
                <Route path='/elocks' element={  <ElocksScreen /> } />
              </> :
              <></>
            }
            <Route path='/settings' element={ <SettingsScreen /> } />
            <Route path='/settings/edit_profile' element={ <EditProfileScreen /> } />
            <Route path='/settings/change_password' element={ <ChangePasswordScreen /> } />
            <Route path='*' element={ <Navigate replace to='/' />} />
          </Routes>
        </div>
      </div>
    </>
  )
}
