import VehicleNotFoundIcon from '../icons/VehicleNotFoundIcon'

const VehiclesNotFound = () => {
  return (
    <div className='empty-search'>
      <VehicleNotFoundIcon />
      <div>No hay vehículos</div>
    </div>
  )
}

export { VehiclesNotFound }
