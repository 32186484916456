import React from 'react';
import { createRoot } from 'react-dom/client';
import { Session } from './components/Session';
import './sass/index.sass'
import 'animate.css'

const root = createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <Session />
  </React.StrictMode>
)
