import VehicleStatusIcon from '../icons/VehicleStatusIcon.js'
import { webfleetToDate, timeInMinutesSince } from '../../helpers/date_helpers.js'
import { getUrlImg } from '../../helpers/webfleet_img_helper.js'

const Item = ({
  vehicle = {},
  objectuidSelected = '',
  onSelectVehicle = () => {}
}) => {
  const isInactive = vehicle => {
    if (!vehicle.pos_time) return true
    const minutes = timeInMinutesSince(webfleetToDate(vehicle.pos_time))
    return minutes > 60 * 24 * 5
  }

  const getClass = vehicle => {
    const isSelected = vehicle.objectuid === objectuidSelected
    const selected   = isSelected ? 'selected' : ''
    const status     = isInactive(vehicle) ? 'inactive' : 'ok'
    return `${selected} ${status}`
  }

  const getStatusClass = vehicle => (
    `status ${isInactive(vehicle) ? 'inactive' : 'ok'}`
  )

  return (
    <li key={ vehicle.objectuid } onClick={ () => onSelectVehicle(vehicle) }>
      <div className={ getClass(vehicle) }>
        <figure>
          <img alt='vehículo' src={ getUrlImg(vehicle) } />
          <div className={ getStatusClass(vehicle) }>
            <span></span>
          </div>
        </figure>
        <figcaption>
          <h2>{ vehicle.objectno } - { vehicle.objectname }</h2>
          <span className='info'>
            <VehicleStatusIcon vehicle={ vehicle } />
            {
              vehicle.speed ?
              (<span className='speed'>({ vehicle.speed } km/h)</span>) :
              (<></>)
            }
            <span className='time'>
              { vehicle.msgtime }
            </span>,
            { vehicle.postext }
          </span>
        </figcaption>
      </div>
    </li>
  )
}

export { Item }
